/* PWA Hooks */
import { useCategory } from '@salesforce/commerce-sdk-react'

/* Hooks */
import { hookNav } from '@helpers/navHelper/navHelper.hooks'

/* Fixed Constants */
const isServer: boolean = typeof window === 'undefined'

/* Shared Types */
import { NavigationData } from '@lib/mock/nav.models'

/* Local Types */
interface ReturnType {
	isLoading: boolean
	data: NavigationData
}

export const use1916Categories = (): ReturnType => {
	const isLoadingPatek = false
	const dataPatek = {}
	/*
	const { isLoading: isLoadingPatek, data: dataPatek } = useCategory({
		parameters: { id: 'patek-philippe', levels: 1 },
		enabled: isServer,
	});
    */
	const { isLoading, data } = useCategory({
		parameters: { id: 'root', levels: 2 },
		enabled: isServer,
	})

	return {
		isLoading: isLoadingPatek || isLoading,
		data: data && dataPatek ? hookNav(data, dataPatek) : false,
	}
}
