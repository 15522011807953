import React, { useEffect } from 'react'

/* Components */
import Modal from '@components/Modal/Modal'
import CloseModal from '@components/Modal/CloseModal'
import SwitchLink from '@components/seo/SwitchLink'

/* Hooks */
import {
	useContactHubData,
	useChatIsAllowed,
} from '@lib/hooks/useContactHubData'

/* Styles */
import ContactHubStyles from './ContactHub.module.scss'

/* Local Types */
interface ContactHubModalProps {
	isOpen: boolean
	toggleModal: () => void
}

const ContactHubModal = ({ isOpen, toggleModal }: ContactHubModalProps) => {
	/* Current locale data */
	const { header, activeLocale } = useContactHubData()
	const contactItems = activeLocale.contactItems

	/* Chat allowed for current locale? */
	const chatIsAllowed = useChatIsAllowed()

	/* Local Listener */
	useEffect(() => {
		document.body?.addEventListener('chat', openIntercomInDirectly)
		return () => {
			document.body?.removeEventListener('chat', openIntercomInDirectly)
		}
	})

	/* Local Handlers */
	const openIntercomInDirectly = () => {
		if (chatIsAllowed && window.Intercom) {
			/* Open Intercom if available for current locale */
			window.Intercom('show')
		} else {
			/* Open Hub instead if Intercom is not available */
			toggleModal()
		}
	}
	const openIntercom = () => {
		if (window.Intercom) {
			window.Intercom('show')
			toggleModal()
		}
	}

	if (contactItems && Object.keys(contactItems).length > 0) {
		const renderContactItems = Object.entries(contactItems).map(
			([key, value]) => {
				switch (key) {
					case 'ti-contact-hub-chat':
						return chatIsAllowed ? (
							<li key={key}>
								<button
									onClick={openIntercom}
									className={ContactHubStyles['ti-contact-hub-modal__item']}
								>
									{value.label}
								</button>
							</li>
						) : null
					case 'ti-contact-hub-whatsapp':
					case 'ti-contact-hub-call':
						return (
							<li key={key}>
								<a
									href={value.url}
									className={ContactHubStyles['ti-contact-hub-modal__item']}
								>
									{value.label}
								</a>
							</li>
						)
					default:
						return (
							<li key={key}>
								<SwitchLink
									href={value.url}
									className={ContactHubStyles['ti-contact-hub-modal__item']}
								>
									{value.label}
								</SwitchLink>
							</li>
						)
				}
			}
		)

		return (
			<Modal
				alignment="right"
				isOpen={isOpen}
				onClickOutside={toggleModal}
				animation="slide-in"
			>
				<div className={ContactHubStyles['ti-contact-hub-modal']}>
					<CloseModal
						closeModal={toggleModal}
						className={ContactHubStyles['ti-contact-hub-modal__close']}
					/>
					<div className={ContactHubStyles['ti-contact-hub-modal__title']}>
						{header['ti-contact-hub-title'].label}
					</div>
					<div className={ContactHubStyles['ti-contact-hub-modal__header']}>
						{header['ti-contact-hub-header'].label}
					</div>
					<p className={ContactHubStyles['ti-contact-hub-modal__content']}>
						{header['ti-contact-hub-content'].label}
					</p>
					<ul className={ContactHubStyles['ti-contact-hub-modal__items']}>
						{renderContactItems}
					</ul>
				</div>
			</Modal>
		)
	}

	return false
}

export default ContactHubModal
