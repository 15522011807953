import React from 'react'
import Script from '@core/script'
import { usePathname } from '@next/navigation'

/* Hooks */
import { useCriteoEvent } from './CriteoHooks'

/* Helpers */
import { useDetectHuman } from '@helpers/hooksHelper'

const CriteoClient = ({
	criteoId,
}: {
	criteoId: string
}): React.ReactElement => {
	/* Skip Bot Traffic */
	const [isHuman] = useDetectHuman(false)

	/* Event Tracking */
	const pathname = usePathname()
	useCriteoEvent(criteoId, pathname, isHuman)

	return (
		<>
			{criteoId && isHuman && (
				<Script src={`//dynamic.criteo.com/js/ld/ld.js?a=${criteoId}`} />
			)}
		</>
	)
}

export default CriteoClient
