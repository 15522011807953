import React from 'react'

/* Helpers */
import { routeRolex } from '@helpers/routeHelper'

/* Styles */
import styles from './RolexPlaque.module.scss'

const RolexPlaque = ({ callbackHandler }: { callbackHandler?: () => void }) => {
	/* Fixed Constants */
	const iframeURL: string =
		'https://static.rolex.com/retailers/clock/?colour=gold&apiKey=366b8d2f8df94cbf8cce59f04411e3ee&lang=en_us'

	/* Variable Constants */
	const clickToURL: string = routeRolex()

	/* Client Events */
	const onKeyPress = (e: React.KeyboardEvent): void => {
		if (e.key === 'Enter' || e.key === ' ') {
			gotoRolex(false, callbackHandler)
		}
	}
	const onClick = (e: React.MouseEvent): void => {
		const newWindow: boolean =
			e && (e.shiftKey || e.ctrlKey || e.metaKey || e.altKey)
		gotoRolex(newWindow, callbackHandler)
	}

	const gotoRolex = (newWindow: boolean, callback?: () => void): void => {
		if (newWindow) {
			window.open(clickToURL, '_blank')
		} else {
			window.location.href = clickToURL
		}

		callback?.()
	}

	return (
		<button className={styles.plaque} onClick={onClick} onKeyPress={onKeyPress}>
			<div className={styles.plaque__wrap}></div>
			<iframe
				className={styles.plaque__iframe}
				id={`rolex_retailer`}
				src={iframeURL}
				scrolling={`no`}
				frameBorder={`no`}
				title={`Rolex`}
			></iframe>
		</button>
	)
}

export default RolexPlaque
