import { useCategory } from '@salesforce/commerce-sdk-react'
import { Request } from 'express'

/* Helpers */
import {
	getNavContextFromSession,
	getCountryAndCurrency,
} from '@helpers/localeHelper'
import { getRemovedSFCCPrefix } from '@helpers/routeHelper'

/* Local Mocks */
import {
	mockEditorials,
	mockSellAndTrade,
	mockExperience,
	mockPlaque,
} from './navHelper.mock'

/* Local Hook */
import { hookDataCategory } from './navHelper.hooks'

/* Shared Types */
import { JSONObject } from '@lib/types/JSONObject.models'
import {
	NavigationData,
	CatalogItem,
	CatalogItemSectionValues,
	ExperienceItem,
	PlaqueItem,
} from '@lib/mock/nav.models'

/* Local Types */
interface GetLinkReturn {
	target: string | undefined
	href: string
}

/* Fixed Config */
const topLevelBrands: string[] = [
	'rlx',
	'rlx-cpo',
	'patek-philippe',
	'watches',
	'pre-owned',
	'jewelry',
]
const goToPrefix: string = 'go-to-'

/* PWA Hook for Top Level Nav */
const getCategory = (cgid: string, levels?: number) => {
	const { data: data } = useCategory(
		{
			parameters: {
				id: cgid,
				levels: levels || 1,
			},
		},
		{
			enabled: true,
		}
	)

	return data
}

/* Get Nav Data from API via Job */
export async function getNav(request?: Request): Promise<NavigationData> {
	const dataCatalog: JSONObject = {}
	const dataCatalogNav: CatalogItem[] = []

	/* Get top level categories from SFCC */
	await Promise.all(
		topLevelBrands.map(async (brand) => {
			const data = await getCategory(brand, 2)
			if (data && data.name) {
				dataCatalog[brand] = await hookDataCategory(data, brand)
			}
		})
	)

	/* Sort promises in order of top level brands */
	topLevelBrands.forEach((brand) => {
		if (dataCatalog[brand]) {
			dataCatalogNav.push(dataCatalog[brand] as unknown as CatalogItem)
		}
	})

	/* TODO - Replace mock data to pull from Strapi or hardcoded config, then type to avoid unknown */
	dataCatalogNav.push(mockEditorials as unknown as CatalogItem)
	dataCatalogNav.push(mockSellAndTrade as unknown as CatalogItem)

	/* TODO - pull from Strapi or navHelper.static.ts, then type to avoid unknown */
	const dataExperience: ExperienceItem[] =
		mockExperience as unknown as ExperienceItem[]
	const dataPlaque: PlaqueItem[] = mockPlaque as unknown as PlaqueItem[]

	/* Return Data */
	const data: NavigationData = {
		catalog: dataCatalogNav,
		experience: dataExperience,
		plaque: dataPlaque,
	}

	/* Get Locale */
	if (request && request.headers) {
		if (dataExperience && dataExperience.length > 0) {
			const locale = await getNavContextFromSession()
			const shipTo = await getCountryAndCurrency(
				locale.country || '',
				locale.currency || ''
			)
			dataExperience.forEach((item) => {
				item.name = item.name.toString().trim().replace('{{locale}}', shipTo)
			})
			data.locale = locale
		}
	}

	return data as NavigationData
}

export const getNavLink = (
	item: CatalogItem,
	val: CatalogItemSectionValues
): GetLinkReturn => {
	if (val && val.key && val.key.indexOf(goToPrefix) > -1) {
		return {
			target: undefined,
			href: '/' + val.key.replace(goToPrefix, '') + '/',
		}
	} else {
		const isExternalLink: boolean =
			(val.href && val.href.indexOf('://') > -1) ||
			(val.key && val.key.indexOf('://') > -1) ||
			false
		if (isExternalLink) {
			return {
				target: '_blank',
				href: val.href || val.key || '',
			}
		} else {
			let href: string = ''
			if (val.href && val.href.indexOf('#') > -1) {
				href = val.href
			} else {
				href =
					'/' +
						item.key +
						'/' +
						(val.href
							? val.href + '/'
							: getRemovedSFCCPrefix(val.key || '', item.key || '') + '/') || ''
			}
			return {
				target: undefined,
				href: href,
			}
		}
	}
}
