import React, { useContext } from 'react'

/* Context */
import { SiteHeaderContext } from '../SiteHeaderProvider'

/* Components */
import { Icon } from '@components/Icon/Icon'
import { Btn } from '@components/Btn/Btn'

/* Styles */
import SearchButtonStyles from './SearchButton.module.scss'

const SearchButton = ({
	type = 'withText',
	text,
	variant,
}: {
	type?: 'icon' | 'withText'
	text?: string
	variant?: 'secondary' | 'primary'
}) => {
	const { showSearch, setShowSearch } = useContext(SiteHeaderContext)

	return (
		<Btn
			className={`${SearchButtonStyles['search']} ${
				type === 'withText' ? SearchButtonStyles['search--with-text'] : ''
			}`}
			variant={`icon`}
			handleClick={() => setShowSearch(!showSearch)}
			ariaLabel={`Search`}
		>
			<Icon
				name={'navSearch'}
				size={'24'}
				title={text}
				variant={variant || `secondary`}
			/>
			{type == 'withText' ? text : ''}
		</Btn>
	)
}

export default SearchButton
