import { getCookie } from '@helpers/cookieHelper'

/* Client-side Only */
export function useSFRAWishlistUUID(): string {
	if (typeof window !== 'undefined') {
		return getCookie('dw_wid') || ''
	} else {
		return ''
	}
}
