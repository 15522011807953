/* Class for external detection */
const bodyFixedClass = 'body-fixed'
const bodyScrollTopClass = 'body-top'

/* Styles */
import '../scss/effects/bodyStuck.scss'

/* Helper */
const bodyStuckEffect = {
	body: (): HTMLElement => document.body,
	currentTop: (): number => window.scrollY || 0,

	currentScroll: function (effect: 'stuck' | 'unstuck'): number {
		return this.willReset()
			? 0
			: effect === 'unstuck'
			  ? this.bodyTop()
			  : this.currentTop()
	},
	willReset: function (): boolean {
		return !!this.body()?.classList.contains(bodyScrollTopClass)
	},
	bodyTop: function (): number {
		return parseInt(this.body()?.style.top ?? 0)
	},
	setClass: function (
		className: string,
		add: boolean,
		addProps: boolean | null
	): void {
		const body = this.body()
		if (body) {
			const classList = body.classList
			if (classList) {
				if (className !== bodyScrollTopClass) {
					classList.remove(bodyScrollTopClass)
				}
				classList[add ? 'add' : 'remove'](className)
			}

			if (typeof addProps === 'boolean') {
				const style = body.style
				if (style) {
					const [position, top, width] = addProps
						? ['fixed', `-${this.currentScroll('stuck')}px`, '100dvw']
						: ['', '', 'auto']
					style.position = position
					style.top = top
					style.width = width
				}
			}
		}
	},
	scroll: function (
		scrollY: number,
		behavior?: ScrollBehavior,
		quick?: number
	): void {
		/* Reset to previous scroll position then scroll to apply animation */
		if (behavior === 'smooth' && quick) {
			this.doScroll(quick, 'instant')
		}
		setTimeout(() => {
			this.doScroll(scrollY, behavior || 'instant')
		}, 1)
	},
	doScroll: function (scrollY: number, behavior: ScrollBehavior): void {
		window?.scrollTo({
			top: scrollY,
			left: 0,
			behavior: behavior,
		})
	},
}

/* Named functions */
export const bodyIsStuck = (): void => {
	bodyStuckEffect.setClass(bodyFixedClass, true, true)
}
export const bodyIsUnstuck = (): void => {
	const scrollY = bodyStuckEffect.currentScroll('unstuck')
	bodyStuckEffect.setClass(bodyFixedClass, false, false)
	if (!isNaN(scrollY)) {
		const willReset: boolean = scrollY == 0 && bodyStuckEffect.willReset()
		const scrollYQuick: number = willReset ? bodyStuckEffect.bodyTop() : scrollY
		const reduceMotion: boolean = window.matchMedia(
			'(prefers-reduced-motion: reduce)'
		).matches
		bodyStuckEffect.scroll(
			scrollY * -1,
			willReset ? (reduceMotion ? 'instant' : 'smooth') : 'instant',
			scrollYQuick * -1
		)
	}
}
export const bodyWillReset = (): void => {
	bodyStuckEffect.setClass(bodyScrollTopClass, true, null)
}
