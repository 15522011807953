import React, { useState, useEffect, useRef } from 'react'

/* Possible States */
const states: {
	loaded: string
	error: string
} = {
	loaded: 'img-loaded',
	error: 'img-error',
}

/* Local Types */
interface propsType {
	src: string | undefined
	mobileSrc?: string | undefined
	quality?: number | undefined
	width: number
	height: number
	alt: string | undefined
	className: string | undefined
	sizes?: boolean | undefined | object
	onError?: (
		e: React.SyntheticEvent<HTMLImageElement, Event>
	) => void | undefined
	fetchPriority?: boolean | undefined
	sourcesets?: sourcesetType
	onLoadCallback?: (img?: HTMLImageElement) => void | undefined
	placeholder?: boolean
	layout?: string
}
export interface sourcesetType {
	[key: string]: [number, number, number] | number[]
}
type optimalSizeType = [number, string]

/* Local Helpers */
const validateProps = (props: propsType): propsType => {
	const {
		src,
		mobileSrc,
		quality,
		width,
		height,
		alt,
		className,
		sizes,
		onError,
		fetchPriority,
		sourcesets,
		onLoadCallback,
		placeholder,
		layout,
	} = props

	/* Validate Sources */
	const validSrc: string =
		src && typeof src === 'string'
			? src
			: placeholder
			  ? getPlaceholder(layout)
			  : src || ''
	const validMobileSrc: string =
		mobileSrc && typeof mobileSrc === 'string' ? mobileSrc : validSrc

	return {
		src: validSrc,
		mobileSrc: validMobileSrc,
		quality: quality || 100,
		width: width || 2500,
		height: height || 2500,
		alt: alt ? alt : typeof src === 'string' ? '' : undefined,
		className: className || '',
		sizes: sizes ? true : false,
		onError: onError || undefined,
		fetchPriority: fetchPriority || undefined,
		sourcesets: sourcesets,
		onLoadCallback: onLoadCallback || undefined,
		placeholder: placeholder || false,
		layout: layout,
	}
}
export const getOptimalSizes = (
	sourcesets?: sourcesetType
): optimalSizeType[] => {
	const defaultSourcesets: sourcesetType = {
		xs: [320, 320, 142],
		lighthouse: [416, 208, 190],
		sm: [640, 320, 302],
		md: [768, 384, 366],
		lg: [1024, 320, 232],
		xl: [1280, 387, 296],
		xxl: [1546, 480, 312],
	}

	const activeSourcesets = sourcesets || defaultSourcesets

	let prevSize: number = 0
	const lastKey: string = Object.keys(activeSourcesets).pop() || ''

	return Object.keys(activeSourcesets).map((key: string) => {
		const [viewportSize, optimalImgSize, preferredImageSize] =
			activeSourcesets[key]
		const width: number =
			(preferredImageSize < optimalImgSize
				? preferredImageSize
				: optimalImgSize) * 2

		const minWidth: string = prevSize > 0 ? `(min-width: ${prevSize}px)` : ''
		const andSplit: string = prevSize > 0 ? ' and ' : ''
		const media: string =
			minWidth +
			(key !== lastKey
				? `${andSplit}(max-width: ${viewportSize - 0.02}px)`
				: '')

		prevSize = viewportSize

		return [width, media.trim()]
	})
}
export const getDensities = (src: string, width: number): string => {
	return [1, 2, 3]
		.map((density) => {
			const atWidth: number = density
				? density == 1
					? (width / 2) * 1.5
					: width / 2
				: width
			return `${src}?auto=format,compress&w=${atWidth}&dpr=${density} ${density}x`
		})
		.join(', ')
}
const getDPRSources = (src: string, dimensions: number): string => {
	const dprSources: string[] = []
	const dpr: number[] = [1, 2, 3]
	dpr.forEach((value) => {
		dprSources.push(`${replaceDimensions(src, dimensions, value)} ${value}x`)
	})
	return dprSources.join(', ')
}
const replaceDimensions = (
	src: string,
	dimensions: number,
	setDPR?: number
): string => {
	const [base, query] = src.split('?')
	const params = new URLSearchParams(query)

	/* Full-size or dpr specific width */
	const atDimension: number = setDPR
		? setDPR == 1
			? (dimensions / 2) * 1.5
			: dimensions / 2
		: dimensions

	/* Repad any smaller images */
	const trim = params.get('trim')
	const currentWidth: string = '1480'
	if (trim && dimensions.toString() !== currentWidth) {
		const pad = params.get('pad') || ''
		const padLeft = params.get('pad-left') || ''
		const padRight = params.get('pad-right') || ''
		if (pad) {
			params.set(
				'pad',
				(parseInt(pad) * (atDimension / parseInt(currentWidth)))
					.toFixed(4)
					.toString()
			)
		}
		if (padLeft) {
			params.set(
				'pad-left',
				(parseInt(padLeft) * (atDimension / parseInt(currentWidth)))
					.toFixed(4)
					.toString()
			)
		}
		if (padRight) {
			params.set(
				'pad-right',
				(parseInt(padRight) * (atDimension / parseInt(currentWidth)))
					.toFixed(4)
					.toString()
			)
		}
	}
	params.set('w', atDimension.toString())
	params.set('h', atDimension.toString())

	/* Load at specific dpr? */
	if (setDPR) {
		params.set('dpr', setDPR === 1 ? '1' : setDPR.toString())
	}

	return base.replace(/ /g, '+') + '?' + params.toString()
}

/* Named Helpers */
export const getImageProps = (props: propsType): propsType => {
	return props
}
export const getPlaceholder = (layout?: string, variant?: string): string => {
	return (
		'https://the1916company.imgix.net/placeholders/' +
		(layout === 'jewelry' ? layout : 'watch') +
		(variant !== 'plain' ? '-grey' : '') +
		'.png'
	)
}

/* Main Component */
const Image = (props: propsType) => {
	const {
		src,
		mobileSrc,
		width,
		height,
		alt,
		className,
		sizes,
		fetchPriority,
		sourcesets,
		placeholder,
		layout,
		onLoadCallback,
	} = validateProps(props)

	/* State */
	const [loaded, setLoaded] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)

	/* Ref */
	const imgRef = useRef<HTMLImageElement>(null)

	/* Client Load Callbacks */
	const onClientLoad = (): void => {
		setLoaded(true)
		onLoadCallback && onLoadCallback(imgRef.current as HTMLImageElement)
	}
	const onClientError = (): void => {
		setError(true)
	}

	/* Initial Load Check */
	useEffect(() => {
		if (imgRef.current?.complete) {
			onClientLoad()
		}
	}, [])

	/* Class Names */
	const classNames: string = [
		className,
		loaded ? states.loaded : '',
		error ? states.error : '',
	]
		.join(' ')
		.trim()

	/* Local Component */
	const getImage = (): React.ReactElement => {
		return (
			<img
				loading={fetchPriority ? `eager` : `lazy`}
				ref={imgRef}
				className={classNames}
				width={width}
				height={height}
				alt={alt}
				src={!error ? src : placeholder ? getPlaceholder(layout) : src}
				onLoad={!error ? onClientLoad : undefined}
				onError={onClientError}
				fetchpriority={fetchPriority ? `high` : undefined}
				style={{ opacity: undefined }}
			/>
		)
	}

	return sizes ? (
		<picture>
			{!error &&
				getOptimalSizes(sourcesets).map((size, index) => {
					const [width, media] = size
					const whichSrc: string =
						(mobileSrc && width < 592 ? mobileSrc : src) || ''
					return (
						<source
							key={index}
							media={media}
							srcSet={width ? getDPRSources(whichSrc, width) : whichSrc}
						/>
					)
				})}
			{getImage()}
		</picture>
	) : (
		getImage()
	)
}

export default Image
