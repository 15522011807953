import { createContext, useContext } from 'react'

/* Local Types */
type BasketContextValues = {
	key: string
	offset: number | null
}
export type BasketContextType = [
	BasketContextValues,
	React.Dispatch<React.SetStateAction<BasketContextValues>>,
	() => void,
]

/* Basket Context Provider */
export const BasketContext = createContext<BasketContextType | null>(null)

/* Basket Context Hook */
export const useBasketContext = (): BasketContextType => {
	const currentBasketContext = useContext(BasketContext)

	if (!currentBasketContext) {
		throw new Error(
			'useBasketContext has to be used within <BasketContext.Provider>'
		)
	}

	return currentBasketContext
}
