import React, { useState, useEffect, useContext, lazy } from 'react'

/* Context */
import { SiteHeaderContext } from './SiteHeaderProvider'

/* Components */
import DesktopBanner from './DesktopBanner/DesktopBanner'
import MobileBanner from './MobileBanner/MobileBanner'

/* Lazy Components */
const MiniHeader = lazy(() => import('./MiniHeader/MiniHeader'))
const MobileMenu = lazy(() => import('./MobileMenu/MobileMenu'))
const SearchMenu = lazy(() => import('./SearchMenu/SearchMenu'))

/* Helpers */
import { getResource } from '@helpers/resourcesHelper'

/* Shared Types */
import { TextItems, PopularItems, NavigationData } from '@lib/mock/nav.models'

/* Styles */
import SiteHeaderStyles from './SiteHeader.module.scss'

/* TODO - move to popular.json pulled from SFCC download job */
const popular: PopularItems = [
	{
		name: 'Rolex',
		link: '/rolex/',
	},
	{
		name: 'Rolex Certified Pre-Owned',
		link: '/rolex-certified-pre-owned/',
	},
	{
		name: 'Patek Philippe',
		link: '/patek-philippe/',
	},
	{
		name: 'De Bethune',
		term: 'de bethune',
	},
	{
		name: 'F.P. Journe',
		term: 'f.p. journe',
	},
]

const SiteHeader = ({ data }: { data: NavigationData }): React.ReactElement => {
	/* Resources for DesktopBanner */
	const text: TextItems = {
		locations: getResource('nav', 'locations', 'Locations'),
		contact: getResource('nav', 'contact', 'Contact Us'),
		account: getResource('nav', 'account', 'Account'),
		wishlist: getResource('nav', 'wishlist', 'Wishlist'),
		search: getResource('nav', 'search', 'Search'),
		close: getResource('nav', 'close', 'Close Menu'),
		menu: getResource('nav', 'menu', 'Open Menu'),
		popular: getResource('nav', 'popular', 'Popular'),
		suggested: getResource('nav', 'suggested', 'Suggested'),
		placeholder: getResource(
			'nav',
			'placeholder',
			'Search for brands or models'
		),
		none: getResource('nav', 'none', 'No results found'),
		loading: getResource('nav', 'loading', 'Searching...'),
	}

	/* Miniheader State */
	const [renderMiniHeader, setRenderMiniHeader] = useState(false)
	const [showHeader, setShowHeader] = useState(false)

	/* MobileMenu & SearchMenu State */
	const { isMobileNavOpen, showSearch } = useContext(SiteHeaderContext)
	const [renderMobileMenu, setRenderMobileMenu] = useState(false)
	const [renderSearchMenu, setRenderSearchMenu] = useState(false)

	/* Render & show header on demand client side */
	useEffect(() => {
		const onScroll = () => {
			/* Scrolled >200px? */
			let shouldShow: boolean = window.scrollY > 200

			/* Fixed body offset by >200px? */
			if (!shouldShow) {
				const bodyTop: string = window
					.getComputedStyle(document.body)
					.getPropertyValue('top')
				const bodyTopInt: number =
					['0', '0px', 'auto'].includes(bodyTop) === false
						? parseInt(bodyTop)
						: 0
				shouldShow = bodyTopInt < -200
			}

			/* Render first time? */
			if (shouldShow && !renderMiniHeader) {
				setRenderMiniHeader(true)
			}

			/* Show now? */
			setShowHeader(shouldShow)
		}
		const stopWatching = () => {
			window.removeEventListener('scroll', onScroll)
		}

		stopWatching()
		window.addEventListener('scroll', onScroll, { passive: true })
		return () => stopWatching()
	}, [])

	/* Render MobileMenu on demand client side */
	useEffect(() => {
		/* Render first time? */
		if (!renderMobileMenu && isMobileNavOpen) {
			setRenderMobileMenu(true)
		}
	}, [isMobileNavOpen])

	/* Render SearchMenu on demand client side */
	useEffect(() => {
		/* Render first time? */
		if (!renderSearchMenu && showSearch) {
			setRenderSearchMenu(true)
		}
	}, [showSearch])

	return (
		<>
			{renderMiniHeader && <MiniHeader data={data} showHeader={showHeader} />}
			<div
				className={`${SiteHeaderStyles['main']}`}
				data-fixed-disable={'true'}
			>
				<header className={`header ${SiteHeaderStyles['header']}`}>
					<DesktopBanner data={data} text={text} />
					<MobileBanner text={text} />
				</header>
			</div>
			{renderMobileMenu && (
				<MobileMenu data={data} text={text} popular={popular} />
			)}
			{renderSearchMenu && <SearchMenu text={text} popular={popular} />}
		</>
	)
}

export default SiteHeader
