import { useEffect } from 'react'

/* PWA Hooks */
import { useLocation as useReactLocation, useHistory } from 'react-router-dom'
import { useSearchParams as usePWASearchParams } from '@salesforce/retail-react-app/app/hooks'

/* PWA Helper Functions */
export const redirect = (url: string) => {
	useEffect(() => {
		location.href = url
	})
}
export const usePathname = () => {
	const location = useReactLocation()
	return location?.pathname || ''
}
export const useQueryString = () => {
	const location = useReactLocation()
	return location?.search || ''
}
export const useQueryParams = () => {
	const queryString = useQueryString()
	return new URLSearchParams(queryString)
}

/* Resplit Search Params Safely */
// Ex: http://localhost:3000/pre-owned/?refine=c_brand%3DA.+Lange+%2526+S%C3%B6hne|Angelus
export const useSafeSearchParams = () => {
	const [searchParams] = usePWASearchParams()
	const historyLocation = useReactLocation()
	historyLocation &&
		historyLocation.search &&
		historyLocation.search
			.replace('?', '')
			.split('&')
			.forEach((param) => {
				const [key, value] = param.split('=')
				if (key === 'limit' || key === 'offset') {
					searchParams[key] = parseInt(value, 10)
				} else if (key === 'sort') {
					searchParams[key] = value
				} else if (key === 'refine') {
					const [refineKey, refineVal]: string[] = value
						.replace(/%3D/g, '=')
						.replace(/%7C/g, '|')
						.split('=')
					const refineValues: string[] =
						refineVal
							?.split('|')
							.map((val) =>
								decodeURIComponent(val)
									.replace(/\+/g, ' ')
									.replace(/\%26/g, '&')
							) || []
					if (refineValues.length > 0) {
						searchParams.refine[refineKey] =
							refineValues.length == 1 ? refineValues[0] : refineValues
					}
				}
			})
	return [searchParams]
}

/* Export from PWA */
export const useLocation = useReactLocation
export const useSearchParams = usePWASearchParams
export const useRouter = useHistory
