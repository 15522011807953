import React from 'react'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { routeHome } from '@helpers/routeHelper'

const BrandLogo = (): React.ReactElement => {
	return (
		<SwitchLink href={routeHome()} aria-label={`Home`}>
			<img
				src="/image/logo-181x102.svg"
				alt="Logo"
				width="181"
				height="102"
				style={{
					margin: '0 auto',
					width: '181px',
					height: '102px',
					maxWidth: '181px',
				}}
			/>
		</SwitchLink>
	)
}

export default BrandLogo
