import React from 'react'

/*
<!-- OneTrust Cookies Settings button start -->
<button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
<!-- OneTrust Cookies Settings button end -->
*/

/* Components */
import OneTrustServer from './OneTrustServer'
import OneTrustClient from './OneTrustClient'

/* Helpers */
import { useDetectHuman } from '@helpers/hooksHelper'

const OneTrust = ({
	oneTrustId,
}: {
	oneTrustId: string
}): React.ReactElement => {
	const [isHuman] = useDetectHuman(false)

	return isHuman ? (
		<>
			<OneTrustServer oneTrustId={oneTrustId} />
			<OneTrustClient />
		</>
	) : (
		<></>
	)
}

export default OneTrust
