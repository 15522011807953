import { useEffect } from 'react'
import { usePathname } from '@next/navigation'
import { useScrollReset } from '@lib/hooks/reducedMotionHook'

/* Named functions */
export function useScrollToTop() {
	const pathname = usePathname()
	useEffect(() => {
		useScrollReset()
	}, [pathname])
}
