/*
Usage:
    import { getScrollBehavior } from '@lib/hooks/reducedMotionHook';
    const defaultScroll = getScrollBehavior(); // smooth or auto
    const smoothScroll = getScrollBehavior('smooth'); // smooth or auto
    const autoScroll = getScrollBehavior('auto'); // auto
    const instantScroll = getScrollBehavior('instant'); // instant or auto

    import { getReducedMotion } from '@lib/hooks/reducedMotionHook';
    const reducedMotion = getReducedMotion(); // true or false

    import { useScrollReset, useScrollIntoView } from '@lib/hooks/reducedMotionHook';
    useScrollReset();
    useScrollIntoView(document.getElementById('foo')); // return boolean
        // true (element was found)
        // false (scrolled to window top/left)
*/

/* Type */
interface reducedMotion {
	check: () => boolean
	behavior: (behavior?: ScrollBehavior) => ScrollBehavior
	isElement: (elem: HTMLElement | null) => boolean
	scroll: (elem: HTMLElement | null, behavior?: ScrollBehavior) => boolean
}
interface blockType {
	block: ScrollLogicalPosition
	inline: ScrollLogicalPosition
}
interface resetType {
	top: number
	left: number
}

/* Fixed Config */
const defaultBehavior: ScrollBehavior = 'smooth'
const autoBehavior: ScrollBehavior = 'auto'
const reducedMotionQuery: string = '(prefers-reduced-motion: reduce)'

/* Helper */
const reducedMotion = {
	/* Protected */
	isElement: function (elem: HTMLElement | null): boolean {
		return typeof elem !== 'undefined' && elem && elem != null ? true : false
	},
	block: function (): blockType {
		return {
			block: 'start',
			inline: 'nearest',
		}
	},
	reset: function (): resetType {
		return {
			top: 0,
			left: 0,
		}
	},

	/* Exportable */
	check: function (): boolean {
		const mediaQueryList: MediaQueryList = window.matchMedia(reducedMotionQuery)
		const prefersReducedMotion: boolean = mediaQueryList.matches
		return prefersReducedMotion
	},
	behavior: function (behavior?: ScrollBehavior | null): ScrollBehavior {
		return this.check() ? autoBehavior : behavior ? behavior : defaultBehavior
	},
	scroll: function (
		elem: HTMLElement | null,
		behavior?: ScrollBehavior
	): boolean {
		let ret = false
		if (this.isElement(elem)) {
			elem?.scrollIntoView({
				...this.block(),
				behavior: this.behavior(behavior),
			})
			ret = true
		} else {
			window.scrollTo({
				...this.reset(),
				behavior: this.behavior(behavior),
			})
		}
		return ret
	},
	scrollExact: function (top: number, behavior?: ScrollBehavior): void {
		window.scrollTo({
			top: top,
			behavior: this.behavior(behavior),
		})
	},
	scrollTo: function (
		elem: HTMLElement | null,
		behavior?: ScrollBehavior | null,
		offset?: number
	): boolean {
		let ret = false
		const bodyIsFixed: boolean =
			window.getComputedStyle(document.body)?.position === 'fixed'
				? true
				: false
		if (this.isElement(elem)) {
			const elemTop: number =
				(elem?.getBoundingClientRect().top || 0) -
				document.body.getBoundingClientRect().top -
				(offset || 0)
			if (bodyIsFixed) {
				document.body.style.top = `-${elemTop}px`
			} else {
				window.scrollTo({
					top: elemTop,
					behavior: this.behavior(behavior),
				})
			}
			ret = true
		} else {
			if (bodyIsFixed) {
				document.body.style.top = `0px`
			} else {
				window.scrollTo({
					...this.reset(),
					behavior: this.behavior(behavior),
				})
			}
		}
		return ret
	},
}

/* Named functions */
export function getReducedMotion(): boolean {
	return reducedMotion.check()
}
export function getScrollBehavior(behavior?: ScrollBehavior): ScrollBehavior {
	return reducedMotion.behavior(behavior)
}

/* Effect Hooks */
export function useScrollIntoView(
	elem: HTMLElement | null,
	behavior?: ScrollBehavior
): boolean {
	return reducedMotion.scroll(elem, behavior)
}
export function useScrollReset(behavior?: ScrollBehavior): void {
	reducedMotion.scroll(null, behavior)
}
export function useScrollDownTo(
	elem: HTMLElement | null,
	behavior?: ScrollBehavior | null,
	offset?: number
): boolean {
	return reducedMotion.scrollTo(elem, behavior, offset)
}
export function useScrollTopTo(top: number, behavior?: ScrollBehavior): void {
	reducedMotion.scrollExact(top, behavior)
}
