import React, { useEffect, useState } from 'react'

/* Helpers */
import { getResource } from '@helpers/resourcesHelper'

/* Components */
import Loader from '@components/loader/Loader'

/* Max time to wait until showing loading indicator */
const maxWaitTime: number = 2000

interface PageLoadingProps {
	maxWait?: number
}

const PageLoading = ({ maxWait }: PageLoadingProps): React.ReactElement => {
	/* Delayed Loading Indicator */
	/* ... Move to useDelayedEvent(timeout, callBack) hook if needed */
	const [delayed, setDelayed] = useState<boolean>(false)
	useEffect(() => {
		const timer = setTimeout(() => {
			setDelayed(true)
		}, maxWait || maxWaitTime)
		return () => clearTimeout(timer)
	})

	/* Direct Styles to Avoid Waiting for Stylesheet */
	const styles: React.CSSProperties = {
		opacity: delayed ? 1 : 0,
		height: '125vh',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		padding: '16px',
		alignItems: 'top',
		textAlign: 'center',
		pointerEvents: 'none' as React.CSSProperties['pointerEvents'],
		userSelect: 'none',
	}

	return (
		<div style={styles as React.CSSProperties}>
			<Loader
				isLoading={true}
				text={getResource('loading', 'title', 'Please Wait...')}
			/>
		</div>
	)
}

export default PageLoading
