import React, { useEffect, useState } from 'react'

/* Hooks */
import { useDetectHuman } from '@helpers/hooksHelper'

const VWOClient = ({
	vwoAlwaysOn,
}: {
	vwoAlwaysOn: boolean
}): React.ReactElement => {
	/* Skip Bot Traffic? */
	const isHuman = useDetectHuman(vwoAlwaysOn)[0]

	/* Prevent repeat loads */
	const [isActive, setIsActive] = useState<boolean>(false)

	/* Move VWO script to head on first human detection */
	useEffect(() => {
		if (isHuman && !isActive) {
			const script = document.querySelector("script[data-source='VWO']")
			if (script) {
				const newScript = document.createElement('script')
				newScript.innerHTML = script.innerHTML
				document.head.appendChild(newScript)
				script.remove()
				setIsActive(true)
			}
		}
	}, [isHuman, isActive])

	return <></>
}

export default VWOClient
