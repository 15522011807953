import React from 'react'

/* Helpers */
import { generateCSSClassString, appendClassStrings } from '@helpers/cssHelpers'
import { routeHome } from '@helpers/routeHelper'

/* Hooks */
import { useOpenLink } from '@lib/hooks/useOpenLink'

/* Local Types */
interface BtnLinkProps {
	id?: string
	children: React.ReactNode

	// Styles
	variant?: 'primary' | 'secondary' | 'subtle' | 'link' | 'icon'
	size?: 'sm' | 'md' | 'lg'

	className?: string // Implementation-specific classes for overrides & customizations

	href: string // For link buttons
	forceNewWindow?: boolean // Force open in new window
}

/* Styles */
import BtnLinkStyles from './Btn.module.scss'

/* Link with Button Styling */
export function BtnLink({
	id,
	children,
	variant = 'primary',
	size = 'md',
	className,
	href,
	forceNewWindow = false,
}: BtnLinkProps) {
	/* Handle link */
	const handleLink = useOpenLink(href, forceNewWindow)

	/* CSS Classes */
	let btnClasses = generateCSSClassString(
		[
			'btn',
			size !== 'md' ? `btn--${size}` : '',
			variant !== 'primary' ? `btn--${variant}` : '',
		],
		BtnLinkStyles
	)
	if (className) {
		btnClasses = appendClassStrings(btnClasses, className)
	}

	/* Use native link to allow for internal/external links and keyboard modifier keys */
	return (
		<a
			id={id || undefined}
			href={href || routeHome()}
			className={btnClasses}
			onClick={handleLink} // TS error
		>
			{children}
		</a>
	)
}
