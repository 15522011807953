import React from 'react'

/* Components */
import SiteHeader from '@components/SiteHeader/SiteHeader'

/* Site Header Contenxt */
import SiteHeaderProvider from '@components/SiteHeader/SiteHeaderProvider'

/* Custom Context for Nav Variations by Country */
import { useCustomerContext } from '@lib/context/customer'

/* Shared Types */
import { NavExclusionsType } from '@components/SiteHeader/header.models'
import { NavigationData } from '@lib/mock/nav.models'

/* Local Types */
interface HeaderProps {
	data: NavigationData
	isLoading?: boolean
}

/* Config */
import exclusionsConfig from '@lib/config/exclusions.json'
const { navExclusions } = exclusionsConfig as NavExclusionsType

const Header = ({ data }: HeaderProps): React.ReactElement => {
	const customerContext = useCustomerContext()
	const { country } = customerContext[0]

	/* Remove Nav Items based on Country */
	if (country && country in navExclusions) {
		if (data && data.catalog) {
			const exclusions: string[] = navExclusions[country]
			const reg: RegExp = new RegExp(exclusions.join('|'))
			data.catalog = data.catalog.filter(
				(item) => !exclusions.includes(item.key)
			)
			data.catalog.forEach((item) => {
				item.vals &&
					Array.isArray(item.vals) &&
					item.vals.forEach((val) => {
						if (val.val && Array.isArray(val.val)) {
							val.val = val.val.filter((v) => !v || !v.key || !reg.test(v.key))
						}
					})
			})
		}
	}

	return (
		<SiteHeaderProvider>
			<SiteHeader data={data || {}} />
		</SiteHeaderProvider>
	)
}

export default Header
