import React from 'react'

/* Helpers */
import { routeHome } from '@helpers/routeHelper'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

/* Styles */
import BrandLogoStyles from './BrandLogo.module.scss'

const BrandLogo = (): React.ReactElement => {
	return (
		<SwitchLink
			className={`${BrandLogoStyles['brand-logo-link']}`}
			href={routeHome()}
			aria-label={`Home`}
		>
			<img
				src="/image/logo-60x35.svg"
				alt="Logo"
				width="60"
				height="35"
				style={{
					width: '60px',
					height: '35px',
					maxWidth: '60px',
				}}
			/>
		</SwitchLink>
	)
}

export default BrandLogo
