import React from 'react'
import { Helmet } from 'react-helmet'

/* Local Types */
interface PreconnectProps {
	sources: {
		[key: string]: string
	}
}

/* Output a list of <link rel='preconnect' href='...' /> */
export default function Preconnect(props: PreconnectProps) {
	return (
		<Helmet>
			{Object.keys(props.sources).map((key: string) => {
				return <link key={key} rel={'preconnect'} href={props.sources[key]} />
			})}
		</Helmet>
	)
}
