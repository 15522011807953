import { useEffect } from 'react'

/* Hooks */
import { useStateChangeAttempt } from './useStateChangeAttempt'

/* Helpers */
import { getCookie } from '@helpers/cookieHelper'

export const useHasCookie = (key: string, val?: string): boolean => {
	const [match, setMatch] = useStateChangeAttempt(true)

	useEffect(() => {
		const cookieVal = getCookie(key)
		if (val) {
			if (cookieVal !== val) {
				setMatch(false)
			}
		} else {
			if (!cookieVal) {
				setMatch(false)
			}
		}
	}, [])

	return match as boolean
}
