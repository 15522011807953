import React, { FC } from 'react'

import Script from '@core/script'

/* Helpers */
import { useDetectHuman } from '@helpers/hooksHelper'

/* Local Types */
interface AudioEyeClientProps {
	config: {
		allowBots: boolean
		url: string
	}
}

const AudioEyeClient: FC<AudioEyeClientProps> = ({
	config,
}): React.ReactElement => {
	const [isHuman] = useDetectHuman(config.allowBots)
	return <>{isHuman && <Script src={config.url} />}</>
}

export default AudioEyeClient
