import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

// PWA Components */
import loadable from '@loadable/component'
import { configureRoutes } from '@salesforce/retail-react-app/app/utils/routes-utils'

/* Exclude Default PWA Routes */
/* import { routes as _routes } from '@salesforce/retail-react-app/app/routes' */

/* Config */
import { getConfig } from '@salesforce/pwa-kit-runtime/utils/ssr-config'

/* Loading Fallback */
import PageLoading from '../lib/components/page/PageLoading'
const fallback = <PageLoading />

/* Home & Error Pages (TBD) */
const Home = loadable(() => import('./pages/home'), { fallback })
const PageNotFound = loadable(() => import('./pages/page-not-found'), {
	fallback,
})
const SomethingWentWrong = loadable(
	() => import('./pages/something-went-wrong'),
	{ fallback }
)

/* Playground Pages */
const Playground = loadable(() => import('./pages/playground'), { fallback })

/* Pages */
const BrandInfo = loadable(() => import('./pages/brand-info'), { fallback })
const Accessibility = loadable(() => import('./pages/accessibility'), {
	fallback,
})
const Careers = loadable(() => import('./pages/careers'), { fallback })
const TopLuxury = loadable(
	() => import('./pages/top-luxury-watch-brands-by-country'),
	{ fallback }
)

/* About Us Pages */
const OurStory = loadable(() => import('./pages/our-story'), { fallback })
const RoadTo1916 = loadable(() => import('./pages/road-to-1916'), { fallback })

/* Customer Service Pages */
const JewelryServices = loadable(() => import('./pages/jewelry-services'), {
	fallback,
})
const Authentication = loadable(() => import('./pages/authentication'), {
	fallback,
})

const CarePlan = loadable(() => import('./pages/care-plan'), { fallback })
const FAQ = loadable(() => import('./pages/faqs'), { fallback })
const PaymentOptions = loadable(() => import('./pages/payment-options'), {
	fallback,
})
const Privacy = loadable(() => import('./pages/privacy'), { fallback })
const ReturnsExchanges = loadable(() => import('./pages/returns-exchanges'), {
	fallback,
})
const ShippingInformation = loadable(
	() => import('./pages/shipping-information'),
	{ fallback }
)
const TermsConditions = loadable(() => import('./pages/terms-conditions'), {
	fallback,
})

/* SLP for Search */
const Search = loadable(() => import('./pages/search'), { fallback })

/* Shop Redirects */
const Shop = loadable(() => import('./pages/shop'), { fallback })

/* WLP for New */
const Watches = loadable(() => import('./pages/watches'), { fallback })
const WatchesBrand = loadable(() => import('./pages/watches'), { fallback })
const WatchesFamily = loadable(() => import('./pages/watches'), { fallback })
const WatchesAllBrands = loadable(() => import('./pages/watches-all-brands'), {
	fallback,
})

/* ULP for Pre-Owned */
const PreOwned = loadable(() => import('./pages/pre-owned'), { fallback })
const PreOwnedBrand = loadable(() => import('./pages/pre-owned'), { fallback })
const PreOwnedFamily = loadable(() => import('./pages/pre-owned'), { fallback })
const PreOwnedAllBrands = loadable(
	() => import('./pages/pre-owned-all-brands'),
	{ fallback }
)

/* KLP for Patek */
const Patek = loadable(() => import('./pages/patek-philippe'), { fallback })
const PatekFamily = loadable(() => import('./pages/patek-philippe'), {
	fallback,
})

/* JLP For Jewelry */
const Jewelry = loadable(() => import('./pages/jewelry'), { fallback })
const JewelryBrand = loadable(() => import('./pages/jewelry'), { fallback })
const JewelryFamily = loadable(() => import('./pages/jewelry'), { fallback })
const JewelryAllBrands = loadable(() => import('./pages/jewelry-all-brands'), {
	fallback,
})

/* Watch Detail Page */
const NewWDP = loadable(() => import('./pages/product-detail-page'), {
	fallback,
})
const PreOwnedWDP = loadable(() => import('./pages/product-detail-page'), {
	fallback,
})
const PatekPDP = loadable(() => import('./pages/product-detail-page'), {
	fallback,
})

/* Jewelry Detail Page */
const JewelryPDP = loadable(() => import('./pages/product-detail-page'), {
	fallback,
})

/* Bespoke and CPO For QA -- Disable for Prod Later */
/*
const Rolex = loadable(() => import('./pages/rolex'), { fallback })
const RolexFamily = loadable(() => import('./pages/rolex'), { fallback })
const RolexCPO = loadable(() => import('./pages/rolex-certified-pre-owned'), { fallback })
const RolexCPOFamily = loadable(() => import('./pages/rolex-certified-pre-owned'), { fallback })
*/

/* All Custom Routes */
const routes = [
	{
		path: '/',
		component: Home,
		exact: true,
	},

	/* Search Routes */
	{
		path: '/search/:layout',
		component: Search,
	},
	{
		path: '/search',
		component: Search,
		exact: true,
	},

	/* Shop Route */
	{
		path: '/shop/:sku',
		component: Shop,
	},

	/* Brand Info Route */
	{
		path: '/watches/:brandId/info',
		component: BrandInfo,
		exact: true,
	},
	{
		path: '/:brandId/info',
		component: BrandInfo,
		exact: true,
	},

	/* PDP Routes */
	{
		path: '/watches/:brandId/:familyId/:productId', // New Watch PDP
		component: NewWDP,
		exact: true,
	},
	{
		path: '/pre-owned/:brandId/:familyId/:productId', // Pre-Owned Watch PDP
		component: PreOwnedWDP,
		exact: true,
	},
	{
		path: '/patek-philippe/:familyId/:productId', // Patek Philippe Watch PDP
		component: PatekPDP,
		exact: true,
	},
	{
		path: '/jewelry/:brandId/:familyId/:productId', // Jewelry PDP
		component: JewelryPDP,
		exact: true,
	},

	/* PLP Routes */
	{
		path: '/patek-philippe/:familyId',
		component: PatekFamily,
	},
	{
		path: '/patek-philippe',
		component: Patek,
	},

	/*
    {
        path: '/rolex-certified-pre-owned/:familyId',
        component: RolexCPOFamily,
    },
    {
        path: '/rolex-certified-pre-owned',
        component: RolexCPO,
    },

    {
        path: '/rolex/:familyId',
        component: RolexFamily,
    },
    {
        path: '/rolex',
        component: Rolex,
    },
    */

	{
		path: '/watches/all-brands',
		component: WatchesAllBrands,
	},
	{
		path: '/watches/:brandId/info',
		component: BrandInfo,
	},
	{
		path: '/watches/:brandId/:familyId',
		component: WatchesFamily,
	},
	{
		path: '/watches/:brandId',
		component: WatchesBrand,
	},
	{
		path: '/watches',
		component: Watches,
	},

	{
		path: '/pre-owned/all-brands',
		component: PreOwnedAllBrands,
	},
	{
		path: '/pre-owned/:brandId/:familyId',
		component: PreOwnedFamily,
	},
	{
		path: '/pre-owned/:brandId',
		component: PreOwnedBrand,
	},
	{
		path: '/pre-owned',
		component: PreOwned,
	},
	{
		path: '/jewelry/all-brands',
		component: JewelryAllBrands,
	},
	{
		path: '/jewelry/:brandId/:familyId',
		component: JewelryFamily,
	},
	{
		path: '/jewelry/:brandId',
		component: JewelryBrand,
	},
	{
		path: '/jewelry',
		component: Jewelry,
	},

	/* Complex Page Routes -- ADD our-team, our-locations */
	{
		path: '/careers',
		component: Careers,
	},
	{
		path: '/care-plan',
		component: CarePlan,
	},
	{
		path: '/accessibility',
		component: Accessibility,
	},
	{
		path: '/top-luxury-watch-brands-by-country',
		component: TopLuxury,
	},

	/* About Us Routes */
	{
		path: '/about-us/our-story/road-to-1916',
		component: RoadTo1916,
	},
	{
		path: '/about-us/our-story/',
		component: OurStory,
	},

	/* Customer Service Routes */
	{
		path: '/accessibility',
		component: Accessibility,
	},
	{
		path: '/faqs',
		component: FAQ,
	},
	{
		path: '/jewelry-services',
		component: JewelryServices,
	},
	{
		path: '/authentication',
		component: Authentication,
	},
	{
		path: '/payment-options',
		component: PaymentOptions,
	},
	{
		path: '/privacy',
		component: Privacy,
	},
	{
		path: '/returns-exchanges',
		component: ReturnsExchanges,
	},
	{
		path: '/shipping-information',
		component: ShippingInformation,
	},
	{
		path: '/terms-conditions',
		component: TermsConditions,
	},

	/* Landing Pages */
	{
		path: '/jewelry',
		component: Jewelry,
		exact: true,
	},

	/* Playground */
	{
		path: '/playground/:playgroundId/',
		component: Playground,
		exact: true,
	},
	{
		path: '/playground/',
		component: Playground,
		exact: true,
	},

	/* Not Found */
	{
		path: '/something-went-wrong',
		component: SomethingWentWrong,
	},
	{
		path: '/page-not-found',
		component: PageNotFound,
	},

	/* Escape Route to SFRA */
	{
		path: '*',
		component: withRouter((props) => {
			const { location } = props
			const urlParams = new URLSearchParams(location.search)

			useEffect(() => {
				if (typeof window !== 'undefined') {
					if (window.location?.href?.match(/\.js$|\.css$/)) {
						window.location.href = window.location.origin + '/file-not-found/'
						return
					}

					let hasEscaped = false
					if (window.location.href.indexOf('localhost:3000') > -1) {
						const appConfig = getConfig()
						if (appConfig) {
							const { proxyConfigs } = getConfig().ssrParameters
							if (proxyConfigs) {
								const ocapiProxyConfig = proxyConfigs.find(
									(config) => config.path === 'ocapi'
								)
								if (ocapiProxyConfig) {
									const localhostURL = 'https://' + ocapiProxyConfig?.host
									if (localhostURL) {
										window.location.href =
											localhostURL +
											window.location.pathname +
											window.location.search
										hasEscaped = true
									}
								}
							}
						}
					}
					if (!hasEscaped) {
						const newURL = new URL(window.location)
						if (!urlParams.has('escape')) {
							newURL.searchParams.append('escape', '1')
							window.location.href = newURL
						}
					}
				}
			}, [location])
			if (urlParams.has('escape')) {
				return <PageNotFound {...props} />
			} else {
				return <PageLoading />
			}
		}),
	},
]

export default () => {
	const config = getConfig()
	return configureRoutes(routes, config, {
		ignoredRoutes: ['/callback', '*'],
	})
}
