import React from 'react'

/* Helpers */
import { routeHome } from '@helpers/routeHelper'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

/* Styles */
import The1916LogoStyles from './The1916Logo.module.scss'

const The1916Logo = (): React.ReactElement => {
	return (
		<SwitchLink
			className={`${The1916LogoStyles['the-1916-logo'] || ''}`}
			href={routeHome()}
			aria-label={`Home`}
		>
			<img
				src="/image/logo-48x49.svg"
				alt="Logo"
				width="48"
				height="49"
				style={{
					width: '48px',
					height: '49px',
					maxWidth: '48px',
				}}
			/>
		</SwitchLink>
	)
}

export default The1916Logo
