import { createContext, useContext } from 'react'

/* Local Types */
type AccountLazyLoadContextValues = object | null
export type AccountLazyLoadContextType =
	| [
			AccountLazyLoadContextValues,
			React.Dispatch<React.SetStateAction<AccountLazyLoadContextValues>>,
	  ]
	| null

/* AccountLazyLoad Context Provider */
export const AccountLazyLoadContext =
	createContext<AccountLazyLoadContextType | null>(null)

/* AccountLazyLoad Context Hook */
export const useAccountLazyLoadContext = (): AccountLazyLoadContextType => {
	return useContext(AccountLazyLoadContext)
}
