import { useEffect, useState } from 'react'

/* Helpers */
import { fetchSafely } from '@helpers/fetchHelper'
import { getSFRAEndpoint } from '@helpers/sfraHelper'

/* Hooks */
import { sendGTAGObject } from '@helpers/hooksHelper'

/* Local Types */
type AccountDataType = AccountVarsType | null
type AccountVarsType = {
	vars: {
		AccountSFID: string
	}
} | null

export const useAccountLazyLoad = (): [
	AccountDataType,
	React.Dispatch<React.SetStateAction<AccountDataType>>,
] => {
	const [accountLazyLoad, setaccountLazyLoad] = useState<AccountDataType>(null)
	useEffect(() => {
		const fetchaccountLazyLoad = async () => {
			const data = (await fetchSafely(
				getSFRAEndpoint('Account-LazyLoad')
			)) as AccountVarsType
			if (data) {
				setaccountLazyLoad(data)
				if (data.vars?.AccountSFID) {
					sendGTAGObject('set', { user_id: data.vars.AccountSFID })
				}
			}
		}
		fetchaccountLazyLoad()
	}, [])

	return [accountLazyLoad, setaccountLazyLoad]
}
