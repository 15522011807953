import React, { useEffect, useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { useLocation, usePathname } from '@next/navigation'

/* Get Config for Sentry & Callrail */
import { getConfig } from '@salesforce/pwa-kit-runtime/utils/ssr-config'

/* Contexts */
import { useCustomer, useScroll } from '@core/locale'
import { CustomerContext, CustomerContextType } from '@lib/context/customer'
import { ScrollContext, ScrollContextType } from '@lib/context/scroll'
import { IndexContext, IndexContextType, useIndex } from '@lib/context/index'
import { HumanContext, HumanContextType } from '@lib/context/human'
import { BasketContext, BasketContextType } from '@lib/context/basket'
import {
	AccountLazyLoadContext,
	AccountLazyLoadContextType,
} from '@lib/context/accountLazyLoad'

/* PWA Base Functionality */
import PropTypes from 'prop-types'
import { getAssetUrl } from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'

/* PWA Hooks */
import useActiveData from '@hooks/useActiveData'
import useEinstein from '@salesforce/retail-react-app/app/hooks/use-einstein'

/* Sentry */
import { useEnableSentry } from '@hooks/useEnableSentry'

/* Global CSS styles */
import '@lib/scss/global/global.scss'

/* Local Project Components */
import Header from '../header'
import Footer from '../footer'
import GlobalMeta from '@components/seo/GlobalMeta'

/* Local Hooks */
import { useDetectHuman } from '@helpers/hooksHelper'
import { useScrollToTop } from '@hooks/useScrollToTop'
import { useAvoidParam } from '@hooks/useAvoidParam'
import { useBasket } from '@hooks/useBasket'
import { useStateChangeAttempt } from '@hooks/useStateChangeAttempt'
import { useAccountLazyLoad } from '@hooks/useAccountLazyLoad'
import { useEscapeFrame } from '@hooks/useEscapeFrame'
import { useEscapeBadDomain } from '@hooks/useEscapeBadDomain'
import { useStalePageCheck } from '@hooks/useStalePageCheck'

/* Middleware Client-Side */
import { clientSideCookies } from '../../middleware/cookieMiddleware/clientSide'

/* 1916 Global Hooks */
import { use1916Categories } from '@next/app'
import ContactHub from '@components/base/ContactHub/ContactHub'

/* Fatal Error Component */
import ErrorPage from '../_error'
const FatalError = ({ error }: { error: Error }) => {
	return (
		<ErrorPage
			skipSentry={true}
			stack={''}
			message={error.message}
			status={500}
		/>
	)
}

// Minimal App Template example which is not dependent on chakra
function App({ children }: { children: React.ReactNode }) {
	const pathname = usePathname()

	/* Prevent Bad Domain or iFrame */
	useEscapeBadDomain()
	useEscapeFrame()
	useStalePageCheck()

	/* Enable Sentry first to capture errors */
	useEffect(useEnableSentry, [])

	/* SSR Categories for Top Nav */
	const { isLoading, data } = use1916Categories()

	/* App Config & Site ID */
	const appConfig = getConfig()?.app || {}
	const activeDataSite: string =
		appConfig.globalsDefaults?.siteId || 'ns-company'

	/* Third Party Client Config */
	const thirdParty = appConfig.thirdParty || {}

	/* Preconnect Sources */
	const preconnect = appConfig.preconnect || {}

	/* Base App Component Requirements */
	const location = useLocation()

	/* Customer Context for Cookies and Active Data*/
	const customerContext = useCustomer()
	const { currency: activeDataCurrency, locale: activeDataLocale } =
		customerContext[0]

	/* Scroll Context for restoring scroll position */
	const scrollContext = useScroll()

	/* Index Context for restoring index visibility */
	const indexContext = useIndex()

	/* Basket Context for Basket Data */
	const basketContext = useBasket()

	/* Active Data State */
	const [activeDataReady, setActiveDataReady] = useStateChangeAttempt({
		dwhead: false,
		dwanalytics: false,
		dwac: false,
	})
	const [allActiveDataReady, setAllActiveDataReady] =
		useStateChangeAttempt(false)

	/* Check Active Data Ready */
	const isAllActiveDataReady = (readyId: string): void => {
		const obj = Object.assign(activeDataReady as { [key: string]: boolean }, {
			[readyId]: true,
		})
		setActiveDataReady(obj)
		if (obj && Object.values(obj).every((value) => value === true)) {
			setAllActiveDataReady(true)
		}
	}

	/* Account Lazy Load For SFRA Context */
	const accountLazyLoadContext = useAccountLazyLoad()

	/* PWA/SFRA Active Data on Human Detection */
	const humanContext = useDetectHuman(false)
	const [isHuman, setIsHuman] = humanContext
	const { trackPage } = useActiveData(true)
	const doTrackPage = useCallback(() => {
		if (isHuman && allActiveDataReady) {
			trackPage(activeDataSite, activeDataLocale, activeDataCurrency)
		}
	}, [
		isHuman,
		allActiveDataReady,
		location,
		activeDataSite,
		activeDataLocale,
		activeDataCurrency,
	])
	useEffect(() => {
		doTrackPage()
	}, [
		isHuman,
		allActiveDataReady,
		location,
		activeDataCurrency,
		activeDataLocale,
	])
	useEffect(() => {
		setIsHuman(isHuman)
	}, [isHuman])

	/* Einstein Tracking */
	const einstein = useEinstein()
	useEffect(() => {
		if (einstein && pathname && typeof window !== 'undefined') {
			try {
				const resolvedPath = new URL(pathname, window.location.origin).pathname
				einstein.sendViewPage(resolvedPath)
			} catch (e) {}
		}
	}, [pathname])

	/* Scroll to top on path change */
	useScrollToTop()

	/* Remove cache key from url */
	useAvoidParam('cache')

	/* Set any client-side cookies based on URL params */
	useEffect(() => {
		clientSideCookies()
	})

	/* Lazy Load Scripts */
	const useLazyLoadScript = (src: string, id: string) => {
		useEffect(() => {
			if (isHuman && src && id) {
				const alreadyScript = document.getElementById(id)
				if (!alreadyScript) {
					const script = document.createElement('script')
					script.src = src
					script.type = 'text/javascript'
					script.id = id
					script.onload = (e) => {
						const elem = (e?.target as HTMLElement) || null
						if (elem) {
							const readyId: string = elem.getAttribute('id') || ''
							if (readyId) {
								isAllActiveDataReady(readyId)
							}
						}
					}
					document.head.appendChild(script)
				} else {
					isAllActiveDataReady(id)
				}
			}
		}, [src, id, isHuman])
	}
	useLazyLoadScript(getAssetUrl('static/head-active_data.js'), 'dwhead')
	useLazyLoadScript(getAssetUrl('static/dwanalytics-22.2.js'), 'dwanalytics')
	useLazyLoadScript(getAssetUrl('static/dwac-21.7.js'), 'dwac')

	/* Preload additional font? */
	const shouldUseCaslon = (): boolean => {
		if (pathname.startsWith('/jewelry/') || pathname === '/') {
			return true
		}
		return false
	}

	return (
		<ErrorBoundary fallbackRender={FatalError}>
			<HumanContext.Provider
				value={humanContext as unknown as HumanContextType}
			>
				<CustomerContext.Provider
					value={customerContext as unknown as CustomerContextType}
				>
					<GlobalMeta
						thirdParty={thirdParty}
						includeCaslon={shouldUseCaslon()}
						preconnect={preconnect}
					/>
					<Helmet>
						{/* Global Styles */}
						<link
							id="styles"
							rel="stylesheet"
							href={getAssetUrl('static/css/layout.css')}
						/>
					</Helmet>

					<div id="app">
						<IndexContext.Provider
							value={indexContext as unknown as IndexContextType}
						>
							<ScrollContext.Provider
								value={scrollContext as unknown as ScrollContextType}
							>
								<BasketContext.Provider
									value={basketContext as unknown as BasketContextType}
								>
									<AccountLazyLoadContext.Provider
										value={
											accountLazyLoadContext as unknown as AccountLazyLoadContextType
										}
									>
										<div className={`page`}>
											<Header data={data} isLoading={isLoading} />
											<main
												className={`content ${
													location.pathname === '/' ? '' : 'app-max-width' // Homepage, full width
												}`}
											>
												{children}
											</main>
											<Footer />
										</div>
										<ContactHub />
									</AccountLazyLoadContext.Provider>
								</BasketContext.Provider>
							</ScrollContext.Provider>
						</IndexContext.Provider>
					</div>
				</CustomerContext.Provider>
			</HumanContext.Provider>
		</ErrorBoundary>
	)
}

App.propTypes = {
	children: PropTypes.node,
}

export default App
