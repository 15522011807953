import React from 'react'
import Image from '@next/image'

/* Helpers */
import { routeBrands } from '@helpers/routeHelper'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

/* Local Types */
type imageDimensionsType = {
	width: number
	height: number
}

/* Styles */
import styles from './PatekPlaque.module.scss'

const PatekPlaque = ({ callbackHandler }: { callbackHandler?: () => void }) => {
	/* Fixed Constants */
	const imgDimensions: imageDimensionsType = {
		width: 119,
		height: 70,
	}
	const imgAt2x: number = imgDimensions.width * 2
	const imgSrc =
		'https://govberg-sfcc.imgix.net/logos/PatekPhilippe_authorized_retailer_Large_500pxl.webp?auto=format,compress&cs=srgb&usm=5&usmrad=5&vib=5&w=' +
		imgAt2x

	/* Variable Constants */
	const clickToURL: string = routeBrands('patek-philippe') + 'info/'

	return (
		<div
			style={{
				width: imgDimensions.width + 'px',
				height: imgDimensions.height + 'px',
				backgroundColor: 'white',
			}}
		>
			<SwitchLink
				onClick={callbackHandler}
				href={clickToURL}
				data-wahfont={`13`}
				role={`link`}
			>
				<Image
					src={imgSrc}
					className={styles.plaque__img}
					width={imgDimensions.width}
					height={imgDimensions.height}
					alt={`Patek Philippe`}
				/>
			</SwitchLink>
		</div>
	)
}

export default PatekPlaque
