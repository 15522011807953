/* Contexts */
import { useCustomerContext } from '@lib/context/customer'

/* Hub Config */
import contactHubConfig from '@lib/data/contactHub.json'
import type { ContactHubConfig } from '@lib/types/ContactHub.models'

/* Countries Config */
import { useSupportedCountries } from '@lib/hooks/useSupportedCountries'

/* Fixed Config */
const defaultFallback: string = 'US'

export const useContactHubData = () => {
	const { country } = useCustomerContext()[0]
	const { header, locales }: ContactHubConfig = contactHubConfig

	/* Supported Countries */
	const countries = useSupportedCountries()

	/* Current Country */
	const getMatchingCountry = countries.find(
		(possibleCountry) => possibleCountry.iso === country
	)

	/* Hub Locale for Country */
	const getDefaultLocale: string = getMatchingCountry
		? getMatchingCountry.defaultLocale
		: defaultFallback

	/* Default to US if no match */
	const activeLocale = getDefaultLocale
		? locales[getDefaultLocale] || locales[defaultFallback]
		: locales[defaultFallback]

	return { header, activeLocale }
}

export const useChatIsAllowed = (): boolean => {
	let ret: boolean = false
	const { activeLocale } = useContactHubData()
	if (activeLocale) {
		const { contactItems } = activeLocale
		if (contactItems) {
			const chatItem = contactItems['ti-contact-hub-chat']
			if (chatItem && chatItem.url) {
				ret = true
			}
		}
	}
	return ret
}
