import React, { lazy } from 'react'

/* Components */
import Favicon from './Favicon'
import Fonts from './Fonts'
import Preconnect from './Preconnect'

/* Lazy Loaded Components */
const Scripts = lazy(() => import('./Scripts'))

/* Hooks */
import { useIsClient } from '@helpers/hooksHelper'

/* Local Types */
interface GlobalMetaProps {
	thirdParty: { [key: string]: boolean }
	includeCaslon?: boolean
	preconnect: { [key: string]: string }
}

const GlobalMeta = ({
	thirdParty,
	includeCaslon,
	preconnect,
}: GlobalMetaProps): React.ReactElement => {
	/* Load Scripts client-side only */
	const isClient = useIsClient()

	return (
		<>
			<Preconnect sources={preconnect} />
			<Fonts includeCaslon={includeCaslon} />
			<Favicon />
			{isClient && <Scripts thirdParty={thirdParty} />}
		</>
	)
}

export default GlobalMeta
