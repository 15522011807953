import React, { useEffect, useRef, useState } from 'react'

/* Wishlist */
export { useCustomerWishlist } from './useCustomerWishlist'

/* Hooks */
import { useStateChangeAttempt } from '@lib/hooks/useStateChangeAttempt'

/* Local Types */
type dataLayerArgs = unknown[]
declare const window: {
	dataLayer: dataLayerArgs
	gtag: Gtag.Gtag // TS Error
} & Window
type paramType = string | boolean | number
type cbType = (e?: Event) => void
interface humanConfigType {
	scrollID: string
	scrollThreshold: number
	events: string[]
}
interface paramsType {
	[key: string]: paramType
}

/* Push data to dataLayer['event', event, optional data] */
/* Usage: sendGTAGEvent('event_name', { event_label: 'string' ... }); */
export function sendGTAGEvent(
	id: string,
	data?: { [key: string]: string | object }
): void {
	if (data) {
		if (data.ecommerce) {
			sendDataLayer({ ecommerce: undefined })
		}
		sendDataLayer({ event: id, ...data })
	} else {
		sendDataLayer({ event: id })
	}
}

/* Push non-event data to dataLayer */
/* Usage: sendGTAGObject('set', { user_id: 'string' }); */
export function sendGTAGObject(
	key: string,
	object: { [key: string]: string | number }
): void {
	if (key && object) {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push([key, object])
	}
}

/* Append to DataLayer */
/* Usage: sendDataLayer({ currency: 'string' ... }); */
export function sendDataLayer(
	data: { [key: string]: string | number | undefined } | string[] | undefined
): void {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push(data)
}

/* Trigger page_view and historyChange events on page navigation */
export function useGTAGEvent(pathname?: string, searchParams?: string): void {
	const skipParams: string[] = ['lang', 'cache']

	useEffect(() => {
		if (typeof window.gtag !== 'undefined') {
			/* Clean parameters before sending to GTAG Event */
			const cleanParams: paramsType = {}
			let cleanQuery: string = ''

			if (searchParams) {
				new URLSearchParams(searchParams).forEach((value, key) => {
					/* Must have:
                        - value defined
                        - non-encoded key
                        - alphanumeric key
                        - key not in skipParams
                    */
					if (
						value &&
						key &&
						key.indexOf('%') == -1 &&
						key.match(/[a-zA-Z0-9]/i) &&
						skipParams.includes(key) === false
					) {
						cleanParams[key] = value.toString()
					}
				})
				cleanQuery = new URLSearchParams(cleanParams).toString() // TS Error
			}

			/* Location with bad params removed */
			const scrubbedPath: string =
				pathname + (cleanQuery ? '?' + cleanQuery : '')

			sendGTAGEvent('page_view', {
				page_location: scrubbedPath,
			})
			sendGTAGEvent('gtm.historyChange')
		}
	}, [pathname, searchParams])
}

/* Initialize Google Tag Manager with dataLayer[] and gtag() */
export function useDataLayer(
	gtmID: string
): [string, React.Dispatch<React.SetStateAction<string>>] {
	const [usedGTMID, setUsedGTMID] = useStateChangeAttempt(gtmID)
	useEffect(() => {
		window.dataLayer = window.dataLayer || []
		window.gtag =
			window.gtag ||
			function (...args: dataLayerArgs) {
				window.dataLayer.push(args)
			}
		window.gtag('js', new Date())
		window.gtag('config', gtmID, {
			send_page_view: false,
		})
	}, [usedGTMID, gtmID])
	return [usedGTMID, setUsedGTMID] // TS Error
}

/* Detect human based on mouse & keyboard events */
export function useDetectHuman(
	defaultState: boolean
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
	const [isHuman, setIsHuman] = useState(defaultState)

	const config: humanConfigType = {
		scrollID: 'scroll',
		scrollThreshold: 10,
		events: [
			'pointerdown',
			'keydown',
			'mousedown',
			'mousemove',
			'mousewheel',
			'touchcancel',
			'scroll',
			'DOMMouseScroll',
			'MozMousePixelScroll',
		],
	}

	useEffect(() => {
		const setEvents = (id: string): void => {
			config.events.forEach((event) => {
				if (id == 'add') {
					if (event.toLowerCase().indexOf(config.scrollID) > -1) {
						window.addEventListener(event, scrolled)
					} else {
						document.addEventListener(event, detected)
					}
				} else if (id == 'remove') {
					if (event.toLowerCase().indexOf(config.scrollID) > -1) {
						window.removeEventListener(event, scrolled)
					} else {
						document.removeEventListener(event, detected)
					}
				}
			})
		}
		const offset = (): number => {
			const supportPageOffset = window.pageXOffset !== undefined
			const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat'
			let currentScroll = supportPageOffset
				? window.pageYOffset
				: isCSS1Compat
				  ? document.documentElement.scrollTop
				  : document.body.scrollTop
			if (currentScroll < 0) {
				currentScroll = 0
			}
			return currentScroll
		}
		const scrolled = (e: Event): void => {
			if (offset() > config.scrollThreshold) {
				detected(e) // TS Error
			}
		}
		const detected = (): void => {
			if (!isHuman) {
				setEvents('remove')
				setIsHuman(true)
			}
		}
		if (!isHuman) {
			setEvents('add')
		}

		return () => {
			setEvents('remove')
		}
	})

	return [isHuman, setIsHuman]
}

export function useClickAway(cb: cbType, params?: paramType[]) {
	const ref = useRef(null)
	useEffect(() => {
		const handler = (e: Event): void => {
			const element = ref.current
			if (element && !element.contains(e.target)) {
				// TS Error
				// TS Error
				try {
					cb.apply(null, [e])
				} catch (err) {
					console.error(err)
				}
			}
		}
		const events: string[] = ['mousedown', 'touchstart']
		events.forEach((event) => document.addEventListener(event, handler))
		return () => {
			events.forEach((event) => document.removeEventListener(event, handler))
		}
	}, params || [])

	return ref
}

export function useMediaQuery(query: string) {
	/* TODO:
        return true (match in client),
        false (no match in client),
        or null (server side to render placeholder)
    */
	const [matches, setMatches] = useStateChangeAttempt(null)

	useEffect(() => {
		const mediaQueryList = window.matchMedia(query)

		const handleChange = (): void => {
			setMatches(mediaQueryList.matches ? true : false)
		}

		mediaQueryList.addEventListener('change', handleChange)
		handleChange()

		return () => {
			mediaQueryList.removeEventListener('change', handleChange)
		}
	})

	return matches
}

export const useIsClient = () => {
	const [isClient, setIsClient] = useStateChangeAttempt(false)

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (!isClient) {
				setIsClient(true)
			}
		}
	})

	return isClient
}
export const useIsLocalHost = () => {
	const [isLocalHost, setIsLocalHost] = useStateChangeAttempt(false)

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (!isLocalHost) {
				setIsLocalHost(window.location.hostname === 'localhost')
			}
		}
	})

	return isLocalHost
}
