import { usePathname, useQueryString } from '@next/navigation'
import { useEffect, useState, useCallback } from 'react'

/* Custom Context for Plaque Variations by Country */
import { useCustomerContext } from '@lib/context/customer'

/* Local Types */
type PlaqueName = 'patek' | 'rolex' | 'tudor' | ''

/* Fixed Config */
const plaqueDefault: PlaqueName[] = ['patek', 'rolex']
const plaqueCombinations: { [key: string]: PlaqueName[] } = {
	patek: ['patek', ''],
	rolex: ['', 'rolex'],
	tudor: ['tudor', 'rolex'],
}
const plaqueCombinationsNewOnly: { [key: string]: PlaqueName[] } = {
	cartier: ['', ''],
}

/* Variable Config -- MOVE to exclusions.json */
const plaqueExclusions: { [key: string]: PlaqueName[] } = {
	SG: ['patek'],
}

/* Returns the plaques we should display based on the current page URL */
const usePlaques = () => {
	const pathname = usePathname()
	const queryString = useQueryString()

	/* Customer Context for Country variations */
	const customerContext = useCustomerContext()
	const { country } = customerContext[0]

	/* Local Callback */
	const getPlaques = useCallback((): PlaqueName[] => {
		let basicPlaques: PlaqueName[] = []
		const urlString: string = (pathname + '|' + queryString)
			.toLowerCase()
			.trim()
		Object.entries(plaqueCombinations).forEach(([key, value]) => {
			if (basicPlaques.length == 0 && urlString.indexOf(key) > -1) {
				basicPlaques = value
			}
		})
		if (urlString.indexOf('/watches/') > -1) {
			Object.entries(plaqueCombinationsNewOnly).forEach(([key, value]) => {
				if (basicPlaques.length == 0 && urlString.indexOf(key) > -1) {
					basicPlaques = value
				}
			})
		}

		if (basicPlaques.length == 0) {
			basicPlaques = plaqueDefault
		}

		/* Exclude Plaques based on Country */
		if (country in plaqueExclusions) {
			basicPlaques = basicPlaques.map((plaque) => {
				return plaqueExclusions[country].includes(plaque) ? '' : plaque
			})
		}

		return basicPlaques
	}, [pathname, queryString, country])

	/* SSR Plaques */
	const [currentPlaques, setCurrentPlaques] =
		useState<PlaqueName[]>(getPlaques())

	/* Client Side Plaques */
	useEffect(() => {
		const newPlaques = getPlaques()
		if (currentPlaques.join('') !== newPlaques.join('')) {
			setCurrentPlaques(newPlaques)
		}
	}, [pathname, queryString])

	return currentPlaques
}

export default usePlaques
