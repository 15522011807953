/* Client-Side Only */

/* Cookies */
import Cookies from 'js-cookie'

/* PWA Hooks */
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'

/* PWA Context */
import { useServerContext } from '@salesforce/pwa-kit-react-sdk/ssr/universal/hooks'

/* 1916 Hooks */
import { useSupportedCountries } from '../hooks/useSupportedCountries'
import { useSupportedCurrencies } from '../hooks/useSupportedCurrencies'
import { useStateChangeAttempt } from '../hooks/useStateChangeAttempt'

/* Helpers */
import { safeParseLanguageAndCountry } from '../helpers/parseHelper'

/* Local Types */
interface configType {
	locale: string
	currency: string
	language: string
	country: string
	countryName: string
	plp?: string
	data?: object
	scroll?: number
}
interface l10nType {
	preferredLocale?: string
	defaultLocale?: string
	preferredCurrency?: string
	defaultCurrency?: string
}
type UseCustomer = [
	configType,
	React.Dispatch<React.SetStateAction<configType>>,
]
type UseScroll = [number, React.Dispatch<React.SetStateAction<number>>]

/* Defaults */
const config: configType = {
	locale: 'en-US', // useLocale()
	currency: 'USD', // useCurrency()
	language: 'en', // useLanguage()
	country: 'US', // useCountry()
	countryName: 'United States', // useCountryName()
}

/* Local Helper */
const getl10n = (): l10nType | false => {
	const { site } = useMultiSite()
	return site ? (site.l10n as l10nType) : false
}

interface RequestHeaders {
	cookie: string
}
interface ReturnCookies {
	[key: string]: string
}

/* Named Exports */
export const useSite = useMultiSite
export const useCookie = (key: string, req?: Request): string => {
	if (typeof window !== 'undefined') {
		return Cookies.get(key) || ''
	} else {
		/* Attempt to pull Request from Server Context */
		if (!req) {
			try {
				const { req: reqServer } = useServerContext()
				req = reqServer
			} catch (e) {
				req = undefined
			}
		}
		const reqHeaders = req?.headers as unknown as RequestHeaders
		if (reqHeaders?.cookie) {
			const cookies = reqHeaders.cookie
				.split(';')
				.reduce((acc: ReturnCookies, cookie: string) => {
					const [key, value] = cookie.trim().split('=')
					acc[key] = value
					return acc
				}, {}) as ReturnCookies
			return cookies[key] || ''
		} else {
			return ''
		}
	}
}
export const useLocale = () => {
	let ret: string = config.locale
	const l10n = getl10n()
	if (l10n) {
		const { preferredLocale, defaultLocale } = l10n
		ret = preferredLocale || defaultLocale || config.locale
	}
	const [language, country] = useLanguageAndCountry(ret)
	return language + '-' + country
}
export const useCurrency = (): string => {
	let ret: string = config.currency
	const l10n = getl10n()
	if (l10n) {
		const { preferredCurrency, defaultCurrency } = l10n
		ret = preferredCurrency || defaultCurrency || config.currency
	}
	ret = (useCookie('currency') || ret).toUpperCase()
	const [currency] = useStateChangeAttempt(ret)
	return currency as string
}
export const useLanguageAndCountry = (def?: string): [string, string] => {
	return safeParseLanguageAndCountry(
		useCookie('language'),
		def || config.locale
	)
}
export const useLanguage = (): string => {
	return useLanguageAndCountry()[0]
}
export const useCountry = (): string => {
	return useCookie('country') || useLanguageAndCountry()[1]
}
export const useCountryName = (country: string): string => {
	const match = useSupportedCountries().find((val) => {
		return val && (val.iso === country || val.name === country)
	})
	return match && match.name ? match.name : country
}
export const useCurrencySymbol = (currency: string): string => {
	const match = useSupportedCurrencies().find((val) => {
		return val && val.key === currency
	})
	return match && match.symbol ? match.symbol : ''
}
export const useShipTo = (): { shipTo: string; country: string } => {
	const country: string = useCountry()
	const currency: string = useCurrency()
	const shipTo: string = useCountryName(country) + ' - ' + currency
	return { shipTo, country }
}
export const useCountryDefaults = (): {
	[key: string]: { currency: string; language: string }
} => {
	return useSupportedCountries().reduce(
		(
			acc: {
				[key: string]: { currency: string; language: string }
			},
			val
		) => {
			acc[val.iso] = {
				currency: val.currency,
				language: val.language,
			}
			return acc
		},
		{}
	)
}

export const useCustomer = (): UseCustomer => {
	const country: string = useCountry()
	const ret: configType = {
		locale: useLocale(),
		country: country,
		countryName: useCountryName(country),
		currency: useCurrency(),
		language: useLanguage(),
		plp: '',
		data: {},
		scroll: 0,
	}
	return useStateChangeAttempt(ret) as UseCustomer
}
export const useScroll = (): UseScroll => {
	return useStateChangeAttempt(0) as UseScroll
}
