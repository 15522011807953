import React, { useCallback, useEffect, useRef, useState } from 'react'

/* Helpers */
import { generateCSSClassString } from '@helpers/cssHelpers'

/* Styles */
import ModalSpecStyles from './Modal.module.scss'

/**
 * Custom modal component which uses {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog the recommended HTML5 <dialog> element}
 *
 * - For use with Parallel and Intercepted routes
 * - NOTE: {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog#open Do not use the `open` attribute} as it does not work with modals
 * @returns
 */
const Modal = ({
	alignment,
	animation,
	onClickOutside,
	children,
	isOpen,
	contentClassName,
}: {
	alignment?: 'left' | 'right' | 'bottom' | 'top' | 'full-screen'
	animation?: 'slide-in' | 'fade-in'
	onClickOutside?: (e: MouseEvent | KeyboardEvent) => void
	children: React.ReactNode
	isOpen: boolean
	contentClassName?: string
}) => {
	const dialogRef = useRef<HTMLDialogElement>(null)
	const [displayModal, setDisplayModal] = useState<boolean>(false)

	/* Local Helpers */
	const handlePossibleClickOutsideModal = useCallback(
		(e: MouseEvent) => {
			if (e && e.target === e.currentTarget) {
				onClickOutside && onClickOutside(e)
			}
		},
		[onClickOutside]
	)
	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Escape' && onClickOutside) {
			e.preventDefault()
			onClickOutside(e as unknown as KeyboardEvent)
		}
	}

	/* Auto-Focus Hook */
	const inputParentRef = useRef<HTMLDivElement>(null)
	const useAutoFocusFirstInput = (): void => {
		useEffect(() => {
			if (isOpen) {
				const firstInput = inputParentRef?.current?.querySelector('input')
				if (firstInput) {
					setTimeout(() => {
						firstInput.focus()
					}, 50)
				}
			} else {
				try {
					const activeElement = document.activeElement
					if (
						activeElement &&
						inputParentRef.current?.contains(activeElement)
					) {
						activeElement.blur()
					}
				} catch (e) {}
			}
		}, [isOpen])
	}
	useAutoFocusFirstInput()

	/* Classes */
	const animationType = () => {
		if (animation === 'fade-in') {
			return 'modal--fade-in'
		} else if (animation === 'slide-in') {
			switch (alignment) {
				case 'left':
					return 'modal--slide-right'
				case 'right':
					return 'modal--slide-left'
				default:
					return ''
			}
		}

		return ''
	}
	const modalClasses = generateCSSClassString(
		['modal', alignment ? `modal--${alignment}` : '', animationType()],
		ModalSpecStyles
	)

	/* Local Effects */
	useEffect(() => {
		// Animate backdrop
		const animateBackdrop = (show: boolean): void => {
			const backdropClass: string = ModalSpecStyles['modal--animate']
			setTimeout(() => {
				if (dialogRef.current) {
					if (show) {
						dialogRef.current.classList.add(backdropClass)
					} else {
						dialogRef.current.classList.remove(backdropClass)
					}
				}
			}, 10)
		}

		// Show modal and trigger slide-in
		if (isOpen && !dialogRef.current?.open) {
			if (dialogRef.current) {
				if ('showModal' in dialogRef.current === false) {
					dialogRef.current.setAttribute('open', 'true')
					dialogRef.current.setAttribute('data-fallback', 'true')
				} else {
					dialogRef.current.showModal()
				}
			}
			setDisplayModal(true)
			animateBackdrop(true)
		}

		// Trigger slide-out and wait for animation to finish before closing
		if (!isOpen && dialogRef.current?.open) {
			setDisplayModal(false)
			animateBackdrop(false)

			setTimeout(() => {
				if (dialogRef.current) {
					if ('showModal' in dialogRef.current === false) {
						dialogRef.current.removeAttribute('open')
					} else {
						dialogRef.current.close()
					}
				}
			}, 370) // Must be close to animation duration
		}
	}, [isOpen])

	useEffect(() => {
		let localRef: HTMLDialogElement | null = null
		if (dialogRef?.current) localRef = dialogRef.current
		localRef?.addEventListener('click', handlePossibleClickOutsideModal)

		return () => {
			localRef?.removeEventListener('click', handlePossibleClickOutsideModal)
		}
	}, [handlePossibleClickOutsideModal])

	return (
		<dialog
			ref={dialogRef}
			className={`${modalClasses} ${
				displayModal ? ModalSpecStyles['modal--open'] : ''
			}`}
			onKeyDown={onKeyDown}
		>
			<div
				className={`${ModalSpecStyles['modal__content']} ${
					contentClassName ?? ''
				}`}
				ref={inputParentRef}
			>
				{children}
			</div>
		</dialog>
	)
}

export default Modal
