import React from 'react'
import { useRouter } from '@next/navigation'

import { Btn } from '../Btn/Btn'
import { Icon } from '../Icon/Icon'

interface CloseModalProps {
	closeModal?: () => void
	size?: '14' | '16' | '20' | '24' | '32' | '44'
	className?: string
}

const CloseModal = ({
	closeModal,
	size = '24',
	className,
}: CloseModalProps) => {
	const router = useRouter()
	const dismiss = closeModal
		? closeModal
		: () => {
				router.goBack()
		  }

	return (
		<Btn
			variant="icon"
			handleClick={dismiss}
			className={className}
			ariaLabel={`Close`}
		>
			<Icon name={'close'} size={size} title="Close" />
		</Btn>
	)
}

export default CloseModal
