import React from 'react'
import { icons } from './icon_map'
import { generateCSSClassString, appendClassStrings } from '@helpers/cssHelpers'

import IconStyles from './Icon.module.scss'

interface IconProps {
	name: keyof typeof icons

	// Styles
	variant?: 'primary' | 'secondary' | 'muted' | 'stroke'
	size?: '14' | '16' | '20' | '24' | '32' | '44'
	isFilled?: boolean

	// Attributes
	className?: string // Implementation-specific classes for overrides & customizations

	ariaHidden?: boolean

	/*
	 * Icons are standalone if they are not decorative and not accomponied by text. In this case,
	 * they should be treated as images and require a role="img" attribute and a title.
	 * Reference: https://css-tricks.com/accessible-svg-icons/
	 */
	title?: string
}

export function Icon({
	name,
	variant = 'primary',
	size = '32',
	isFilled = false,
	className,
	ariaHidden,
	title,
}: IconProps) {
	const viewBox = '0 0 24 24'
	const icon = icons[name]

	// CSS
	let iconClasses = generateCSSClassString(
		[
			'icon',
			variant !== 'primary' ? `icon--${variant}` : '',
			`icon--${size}`,
			icon.fillBased || isFilled ? `icon--filled` : '',
			icon.fillBased ? 'icon--no-stroke' : '',
		],
		IconStyles
	)

	if (className) {
		iconClasses = appendClassStrings(iconClasses, className)
	}

	return (
		<svg
			viewBox={viewBox}
			className={iconClasses}
			aria-hidden={ariaHidden}
			role={title ? 'img' : undefined}
		>
			{title && <title>{title}</title>}
			{icon.path}
		</svg>
	)
}
