import React, { useEffect, useRef, useState } from 'react'

import LoaderStyles from './Loader.module.scss'

interface LoaderProps {
	/**
	 * Whether or not the loader is loading
	 */
	isLoading: boolean
	/**
	 * The text to display with the loader
	 */
	text?: string
	/**
	 * The time in milliseconds to wait before showing the loader
	 */
	timeout?: number
}

const LOADER_IMAGE_URL =
	'https://the1916company.imgix.net/1916_BLACK_WHITE_CIRCLE_V3.gif'

const Loader = ({
	isLoading = false,
	text = 'Please Wait...',
	timeout = 600,
}: LoaderProps) => {
	const loaderRef = useRef<HTMLDivElement>(null)
	const [shouldShowLoader, setShouldShowLoader] = useState(false)

	useEffect(() => {
		if (isLoading) {
			// Set 600ms timer before showing loader to prevent screen flicker
			const timeoutId = setTimeout(() => {
				setShouldShowLoader(true)
			}, timeout)

			return () => {
				clearTimeout(timeoutId)
			}
		}

		setShouldShowLoader(false)
	}, [isLoading])

	if (!shouldShowLoader) return null

	return (
		<div ref={loaderRef} className={LoaderStyles['container']}>
			<div className={LoaderStyles['loader']}>
				<img
					src={LOADER_IMAGE_URL}
					alt="Loading"
					className={LoaderStyles['loader__image']}
				/>
				<p className={LoaderStyles['loader__text']}>{text}</p>
			</div>
		</div>
	)
}

export default Loader
