import { useState, useEffect, useMemo } from 'react'
import {
	getSupportedCurrencies,
	SupportedCurrencies,
} from '@helpers/currencyHelper'

export function useSupportedCurrencies() {
	const [supportedCurrencies, setSupportedCurrencies] = useState<
		SupportedCurrencies[]
	>([])

	// Memoize the results
	const getCurrenciesMemoized = useMemo(() => {
		const getCurrencies = async () => {
			const currencyData = await getSupportedCurrencies()
			setSupportedCurrencies(currencyData.supportedCurrencies)
		}

		return getCurrencies
	}, [])

	useEffect(() => {
		getCurrenciesMemoized()
	}, [getCurrenciesMemoized])

	return supportedCurrencies
}
