import React, { lazy } from 'react'

/* Third Party Global Components */
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ThirdParty from '@components/third-party/ThirdParty'

/* Hooks */
import { useFirstRenderOnScroll } from '@lib/hooks/useFirstRenderOnScroll'
import { useHasCookie } from '@lib/hooks/useHasCookie'

/* Custom Global Components */
const SubscribeModal = lazy(
	() => import('@components/subscribe-modal/SubscribeModal')
)

/* Third Party Styles */
import '../../scss/third-party/grecaptcha.scss'

/* Global Styles */
import '../../scss/global/global.scss'
import '../../scss/global/reset.scss'
import '../../scss/global/utilities.scss'

const SiteTemplate = ({
	children,
	thirdParty,
	reCaptcha,
}: {
	children: React.ReactNode
	thirdParty: { [key: string]: string }
	reCaptcha: { [key: string]: string }
}): React.ReactElement => {
	/* Wait for interaction & cookie for conditional subscribe modal render */
	const hasCookie = useHasCookie('subscribe', 'true')
	const render = useFirstRenderOnScroll(300)

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={reCaptcha.publicKey}
			language={'en'}
			useRecaptchaNet={false}
			scriptProps={{
				async: true,
				defer: true,
			}}
		>
			<ThirdParty thirdParty={thirdParty} />
			{children}
			{render && !hasCookie && <SubscribeModal />}
		</GoogleReCaptchaProvider>
	)
}

export default SiteTemplate
