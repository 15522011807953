import { useState, useEffect, useMemo } from 'react'

/* Local Types */
export type SupportedCountries = {
	iso: string
	currency: string
	language: string
	name: string
	defaultLocale: string
}
export type SupportedCountriesConfig = {
	supportedCountries: SupportedCountries[]
}

/* Config */
import supportedCountriesConfig from '@lib/config/supportedCountries.json'
const { supportedCountries } = supportedCountriesConfig

export function useSupportedCountries() {
	const [countries, setCountries] = useState<SupportedCountries[]>([])

	// Memoize the results
	const getCountriesMemoized = useMemo(() => {
		const getCountries = () => {
			setCountries(supportedCountries)
		}

		return getCountries
	}, [])

	useEffect(() => {
		getCountriesMemoized()
	}, [getCountriesMemoized])

	return countries
}
