import { useEffect } from 'react'
import {
	useCustomerId,
	useCustomerProductLists,
	useShopperCustomersMutation,
} from '@salesforce/commerce-sdk-react'

import { isServer } from '@lib/utils/baseUtils'

export type WishlistType = {
	id: string
	customerProductListItems: {
		id: string
		productId: string
	}[]
} | null

/* Hooks */
import { useSFRAWishlistUUID } from '@lib/hooks/useSFRAWishlistUUID'
import { useStateChangeAttempt } from '@lib/hooks/useStateChangeAttempt'

export const useCustomerWishlist = () => {
	const [wishlist, setWishlist] = useStateChangeAttempt(null) as [
		WishlistType,
		React.Dispatch<React.SetStateAction<WishlistType>>,
	]
	const customerId = useCustomerId()

	// Query for all product lists related to current customer
	const { data: wishlists, fetchStatus } = useCustomerProductLists(
		{
			parameters: {
				customerId: customerId,
			},
		},
		{
			enabled: !!customerId && !isServer,
		}
	)

	/* Create Wishlist? */
	const createWishlist = useShopperCustomersMutation(
		'createCustomerProductList'
	)
	useEffect(() => {
		if (
			customerId &&
			fetchStatus === 'idle' &&
			(!wishlists || wishlists.total == 0)
		) {
			createWishlist.mutate({
				parameters: {
					customerId: customerId,
				},
				body: {
					type: 'wish_list',
				},
			})
		}
	}, [fetchStatus])

	/* Set Wishlist? */
	useEffect(() => {
		if (wishlists && wishlists.data) {
			const wishlistUUID = useSFRAWishlistUUID()

			let foundWishlist = wishlistUUID
				? wishlists.data.find((list) => list.UUID === wishlistUUID)
				: null
			if (!foundWishlist) {
				foundWishlist = wishlists.data.find((list) => list.type === 'wish_list')
			}

			setWishlist(foundWishlist as typeof wishlist)
		}
	}, [wishlists])

	return wishlist
}
