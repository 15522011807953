import React, { createContext, useEffect, useState } from 'react'

const resizeEvent: string = 'resize'

import { bodyIsUnstuck, bodyIsStuck } from '@lib/effects/bodyStuckEffect'

interface SiteHeaderContextType {
	isMobileNavOpen: boolean
	setIsMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>
	shouldFocusSearch: boolean
	setShouldFocusSearch: React.Dispatch<React.SetStateAction<boolean>>
	showSearch: boolean
	setShowSearch: React.Dispatch<React.SetStateAction<boolean>>
}

export const SiteHeaderContext = createContext<SiteHeaderContextType>(
	{} as SiteHeaderContextType
)

const SiteHeaderProvider = ({ children }: { children: React.ReactNode }) => {
	const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false)
	const [shouldFocusSearch, setShouldFocusSearch] = useState<boolean>(false)
	const [showSearch, setShowSearch] = useState<boolean>(false)

	const [isDesktop, setIsDesktop] = useState<boolean>(
		typeof window !== 'undefined' && window.innerWidth >= 1200
	)

	useEffect(() => {
		const getCurrentSize = () => {
			const isDesktopNow: boolean = window.innerWidth >= 1200
			if (isDesktopNow !== isDesktop) {
				setIsDesktop(isDesktopNow)
				if (isDesktopNow) {
					setIsMobileNavOpen(false)
				}
			}
		}
		getCurrentSize()

		window.addEventListener(resizeEvent, getCurrentSize)
		return () => {
			window.removeEventListener(resizeEvent, getCurrentSize)
		}
	})

	useEffect(() => {
		if (isMobileNavOpen || showSearch) {
			bodyIsStuck()
		} else {
			bodyIsUnstuck()
		}
		return () => {
			bodyIsUnstuck()
		}
	}, [isMobileNavOpen, showSearch])

	return (
		<SiteHeaderContext.Provider
			value={{
				isMobileNavOpen,
				setIsMobileNavOpen,
				shouldFocusSearch,
				setShouldFocusSearch,
				showSearch,
				setShowSearch,
			}}
		>
			{children}
		</SiteHeaderContext.Provider>
	)
}

export default SiteHeaderProvider
