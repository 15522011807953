// TODO: All of this is placeholders for US, USD, en only

import Cookies from 'js-cookie'

export const getNavContextFromSession = () => {
	return {
		country: 'US',
		currency: 'USD',
	}
}
export const getCountryAndCurrency = (country: string, currency: string) => {
	return country + ' - ' + currency
}

export const getShipTo = () => {
	return {
		shipTo: 'US - USD',
		country: 'US',
	}
}

export const getAllCountries = () => {
	return {
		US: 'United States',
	}
}
export const getAllLanguages = () => {
	return {
		en: 'English',
	}
}
export const getAllCurrencies = () => {
	return {
		USD: 'US Dollar',
	}
}
export const getLocaleSpecifics = () => {
	const currency: string = Cookies.get('currency') || 'USD'
	const [language, country]: [string, string] = (
		Cookies.get('language') || 'en_US'
	).split('_')
	return {
		country: country.toLowerCase(),
		language: language.toLowerCase(),
		currency: currency.toUpperCase(),
	}
}
export const getCountryDefaults = () => {
	return {
		US: {
			currency: 'USD',
			language: 'en',
		},
	}
}
