import React from 'react'

/* Helpers */
import { routeHome } from '@helpers/routeHelper'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

/* Styles */
import BrandLogoStyles from './BrandLogo.module.scss'

const BrandLogo = (): React.ReactElement => {
	return (
		<SwitchLink
			className={`${BrandLogoStyles['brand-logo-link']}`}
			href={routeHome()}
			aria-label={`Home`}
		>
			<img
				src="/image/logo-176x100.svg"
				alt="Logo"
				width="176"
				height="100"
				style={{
					width: '176px',
					height: '100px',
					maxWidth: '176px',
				}}
			/>
		</SwitchLink>
	)
}

export default BrandLogo
