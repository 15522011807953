import React from 'react'

import { Btn } from '@components/Btn/Btn'
import ContactHubModal from './ContactHubModal'

import { useToggle } from '@lib/hooks/useToggle'

import ContactHubStyles from './ContactHub.module.scss'

const ContactHub = () => {
	const [isContactHubOpen, toggleContactHubOpen] = useToggle(false)

	return (
		<div className={ContactHubStyles['ti-launcher']}>
			<Btn
				id="contactHub"
				variant={`icon`}
				handleClick={toggleContactHubOpen}
				className={ContactHubStyles['ti-launcher__button']}
				ariaLabel={`Open Contact Hub`}
			></Btn>
			<ContactHubModal
				isOpen={isContactHubOpen}
				toggleModal={toggleContactHubOpen}
			/>
		</div>
	)
}

export default ContactHub
