/* Local Types */
interface ResponseCookie {
	name: string
	value: string
	path?: string
	expires?: Date
}
type possibleCookies = ResponseCookie[]
interface ParamsType {
	[key: string]: string
}

/* Local Helpers */
const hasForeignChars = (value: string): boolean => {
	return value && /[^\x00-\x7F]+/.test(value) ? true : false
}
const getSingleCookieValue = (
	RequestCookies: ParamsType[],
	findKey: string
): string => {
	let ret: string = ''
	if (typeof window !== 'undefined') {
		const cookie = document?.cookie
			?.split(';')
			.find((cookie) => cookie.trim().startsWith(findKey + '='))
		if (cookie) {
			ret = cookie.split('=')[1] || ''
		}
	} else if (RequestCookies) {
		Object.entries(RequestCookies).forEach(([key, val]) => {
			if (!ret && val && key === findKey) {
				ret = (val || '').toString()
			}
		})
	}
	return ret || ''
}

/* Server-side or client-side */
export const addAffiliateCookies = (
	params: ParamsType,
	RequestCookies?: ParamsType[]
): possibleCookies => {
	const ret: possibleCookies = []
	const utm: { [key: string]: string } = {}
	const affiliateKey: string = 'affiliate'
	let anyFound: boolean = false

	/* Expiration days */
	const cookieDays: number = 60

	/* Set expiration date */
	const expires = new Date()
	expires.setDate(expires.getDate() + cookieDays)

	/* Get possible affilate params */
	if (params) {
		Object.entries(params).forEach(([key, val]) => {
			if (
				key.startsWith('utm_') ||
				['msclkid', 'fbclid', 'gclid'].includes(key)
			) {
				if (val) {
					utm[key] = val
					anyFound = true
				}
			}
		})
	}

	/* Re-encode bad cookies? */
	if (!anyFound && RequestCookies) {
		Object.entries(RequestCookies).forEach(([key, val]) => {
			if (key.startsWith(`${affiliateKey}_`)) {
				const value = val?.toString() || ''
				if (hasForeignChars(value)) {
					const affiliateSingleCookie: ResponseCookie = {
						name: key,
						value: encodeURIComponent(value),
						path: '/',
						expires: expires,
					}
					ret.push(affiliateSingleCookie)
				}
			}
		})
	}

	/* Medium set? */
	if (anyFound && utm['utm_medium']?.trim().length > 0) {
		/* Get existing GA_gclid cookie */
		const getGclidValue = (): string => {
			let ret: string = ''
			if (RequestCookies) {
				const key: string = 'GA_gclid'
				const existingGclid = getSingleCookieValue(
					RequestCookies,
					`${affiliateKey}_${key}`
				)
				if (existingGclid) {
					ret = existingGclid
				} else {
					const affiliate = getSingleCookieValue(RequestCookies, affiliateKey)
					if (affiliate) {
						try {
							const vals = JSON.parse(decodeURIComponent(affiliate))
							ret = vals ? vals[key] : ''
						} catch (e) {}
					}
				}
			}
			return ret || ''
		}

		/* Affiliate values to match SFRA logic */
		const affiliateVars: ParamsType = {
			ga_campaign_id: utm['utm_id'] || '',
			GA_Campaign: utm['utm_campaign'] || '',
			GA_Content: utm['utm_content'] || '',
			GA_gclid:
				(utm['fbclid'] || utm['msclkid'] || utm['gclid'] || '').trim() ||
				getGclidValue() ||
				'',
			Ga_Source: utm['utm_source'] || '',
			GA_Term: utm['utm_term'] || '',
			GA_Medium: utm['utm_medium'] || '',
		}

		/* Individual cookies */
		Object.entries(affiliateVars).forEach(([key, value]) => {
			const affiliateSingleCookie: ResponseCookie = {
				name: `${affiliateKey}_${key}`,
				value: hasForeignChars(value) ? encodeURIComponent(value) : value,
				path: '/',
				expires: expires,
			}
			ret.push(affiliateSingleCookie)
		})

		/* Affiliate cookie - double stringify to match SFRA logic  */
		const affiliateCookie: ResponseCookie = {
			name: affiliateKey,
			value: encodeURIComponent(JSON.stringify(affiliateVars)),
			path: '/',
			expires: expires,
		}
		ret.push(affiliateCookie)
	}

	/* Return Cookies */
	return ret
}

/* Client-side only */
export const clientSideCookies = (): possibleCookies => {
	if (typeof window !== 'undefined') {
		const params = new URLSearchParams(window.location.search)
		if (params) {
			/* Convert URLSearchParams to object */
			const paramsObj: ParamsType = {}
			params.forEach((value, key) => {
				paramsObj[key] = value
			})

			/* Add cookies client-side? */
			const cookies = addAffiliateCookies(paramsObj)
			if (cookies.length) {
				cookies.forEach((cookie) => {
					document.cookie = `${cookie.name}=${cookie.value}; path=${cookie.path}; expires=${cookie.expires};`
				})
				return cookies
			}
		}
	}

	return []
}
