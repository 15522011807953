import React, { useEffect, useRef, useState } from 'react'
import { Icon } from '@lib/components/Icon/Icon'
import { isServer } from '@lib/utils/baseUtils'
import { Btn } from '@lib/components/Btn/Btn'
import { BtnLink } from '@lib/components/Btn/BtnLink'

import MobileBannerStyles from './MobileAppBanner.module.scss'
import { useHumanContext } from '@lib/context/human'

// Utils
const hasUserClosedBanner = () => {
	try {
		const bannerClosedDate = localStorage.getItem('appBannerClosedDate')
		const ttl = 1000 * 60 * 60 * 24 * 3 // 3 days

		if (bannerClosedDate) {
			const currentDate = new Date().getTime()
			const closedDate = parseInt(bannerClosedDate, 10)

			if (currentDate - closedDate < ttl) {
				return true
			}
		}
	} catch (err) {}

	return false
}

const checkIsSafari = () => {
	if (isServer) return false

	const { userAgent } = navigator

	const isAppleDevice = /iPhone|iPad|iPod|Macintosh/.test(userAgent)
	const isSafari =
		/Safari/.test(userAgent) && !/Chrome|CriOS|FxiOS/.test(userAgent)

	return isAppleDevice && isSafari
}

const checkIsAndroidChrome = () => {
	if (isServer) return false

	const { userAgent } = navigator

	const isChrome =
		/Safari/.test(userAgent) && /Chrome/.test(userAgent) && !!window.chrome
	const isAndroid = /Android/.test(userAgent)

	return isChrome && isAndroid
}

// Types
interface BeforeInstallPromptEvent extends Event {
	readonly platforms: string[]
	readonly userChoice: Promise<{
		outcome: 'accepted' | 'dismissed'
		platform: string
	}>
	prompt(): Promise<void>
}

declare global {
	interface WindowEventMap {
		beforeinstallprompt: BeforeInstallPromptEvent
	}
}

interface MobileAppBannerProps {
	isAppBannerVisible: boolean
	setIsAppBannerVisible: (isVisible: boolean) => void
}

// Component
const MobileAppBanner = ({
	isAppBannerVisible,
	setIsAppBannerVisible,
}: MobileAppBannerProps) => {
	const [ctaType, setCtaType] = useState<'androidBtn' | 'onelink' | null>(null)
	const [timeoutFinished, setTimeoutFinished] = useState(false)
	const isHuman = useHumanContext()?.[0]
	const deferredPromptRef = useRef<BeforeInstallPromptEvent | null>(null)

	// Identify browser
	const isSafari = checkIsSafari()
	const isAndroidChrome = checkIsAndroidChrome()

	// Android Button or Link
	useEffect(() => {
		// Mobile third-party
		if (!isSafari && !isAndroidChrome && !hasUserClosedBanner()) {
			setCtaType('onelink')
			setIsAppBannerVisible(true)
		}

		// Set beforeinstallprompt event for Android Chrome with play store link fallback
		if (isAndroidChrome && !hasUserClosedBanner()) {
			const waitForInstallPrompt = setTimeout(() => {
				// Fallback if beforeInstallPrompt doesn't fire
				setCtaType('onelink')
				setTimeoutFinished(true)
			}, 3000)

			const storeInstallPrompt = (e: BeforeInstallPromptEvent) => {
				clearTimeout(waitForInstallPrompt)

				// Prevent Chrome 67 and earlier from automatically showing the prompt
				e.preventDefault()

				// Stash the event so it can be triggered later.
				deferredPromptRef.current = e
				setCtaType('androidBtn')
				setIsAppBannerVisible(true)
			}

			window.addEventListener('beforeinstallprompt', storeInstallPrompt)

			return () => {
				window.removeEventListener('beforeinstallprompt', storeInstallPrompt)
			}
		}
	}, [])

	useEffect(() => {
		if (timeoutFinished && isHuman && !hasUserClosedBanner()) {
			setIsAppBannerVisible(true)
		}
	}, [timeoutFinished, isHuman])

	const handleInstallClick = () => {
		if (deferredPromptRef.current !== null) {
			deferredPromptRef.current.prompt()
			// Wait for the user to respond to the prompt
			deferredPromptRef.current.userChoice.then(() => {
				deferredPromptRef.current = null
				setIsAppBannerVisible(false)
			})
		}
	}

	const handleClose = () => {
		try {
			const currentDate = new Date()
			localStorage.setItem(
				'appBannerClosedDate',
				currentDate.getTime().toString()
			)
		} catch (err) {}

		setIsAppBannerVisible(false)
	}

	// UI Values
	let label: string | null = null
	let cta: JSX.Element | null = null

	switch (ctaType) {
		case 'androidBtn':
			label = 'Free on Google Play'
			cta = (
				<Btn
					handleClick={handleInstallClick}
					className={MobileBannerStyles['play-elem__install-btn']}
				>
					Install
				</Btn>
			)
			break
		case 'onelink':
			label = 'Download For Free'
			cta = (
				<BtnLink
					href="https://onelink.to/4tcv6y"
					className={MobileBannerStyles['play-elem__install-btn']}
				>
					View
				</BtnLink>
			)
			break
	}

	// Use native smart app banner on Safari (Search 'meta name="apple-itunes-app"' in codebase to update)
	if (isSafari || !isAppBannerVisible || hasUserClosedBanner()) {
		return null
	}

	return (
		cta && (
			<div
				id="playElem"
				className={`${MobileBannerStyles['play-elem']} u-hidden-desktop`}
			>
				<div className={MobileBannerStyles['play-elem__btn-wrapper']}>
					<Btn
						variant="icon"
						handleClick={handleClose}
						className={MobileBannerStyles['play-elem__close-btn']}
						ariaLabel="Close"
					>
						<Icon name="close" size="24" />
					</Btn>
				</div>
				<div className={MobileBannerStyles['play-elem__img-wrapper']}>
					<img
						src="https://the1916company.imgix.net/App_logo.png?auto=format,compress&w=100"
						className={MobileBannerStyles['play-elem__img']}
						alt="The 1916 Company Logo"
						width="50"
						height="50"
					/>
				</div>
				<div className={MobileBannerStyles['play-elem__content']}>
					<h2
						className={`${MobileBannerStyles['play-elem__txt']} ${MobileBannerStyles['play-elem__heading']}`}
					>
						The 1916 Company
					</h2>
					<h3
						className={`${MobileBannerStyles['play-elem__txt']} ${MobileBannerStyles['play-elem__desc']}`}
					>
						Official App
					</h3>
					<p
						className={`${MobileBannerStyles['play-elem__txt']} ${MobileBannerStyles['play-elem__desc']}`}
					>
						{label}
					</p>
				</div>
				<div className={MobileBannerStyles['play-elem__btn-wrapper']}>
					{cta}
				</div>
			</div>
		)
	)
}

export default MobileAppBanner
