/* eslint-disable @typescript-eslint/no-explicit-any */
import { ACTIVE_DATA_ENABLED } from '../../app/constants'

/* Active data queue */
const activeDataQueue: {
	type: string
	params: any
}[] = []

/* Local Types */
declare global {
	interface Window {
		dw: any
	}
}
interface Helper {
	sendViewProduct(category: any, product: any, type: any): void
	sendViewCategory(
		searchParams: any,
		category: any,
		productSearchResult: any
	): void
	trackPage(siteId: any, localeId: any, currency: any): void
	sendViewSearch(searchParams: any, productSearchResult: any): void
}

/* Timer to send active data */
let activeDataTimer: NodeJS.Timeout | null = null

const useActiveData = (startTimer?: boolean) => {
	/* Check if active data can be tracked */
	const canTrack = () => {
		return (
			ACTIVE_DATA_ENABLED &&
			typeof window !== 'undefined' &&
			typeof window.dw !== 'undefined'
		)
	}

	/* Active data functions */
	const helper: Helper = {
		async sendViewProduct(category, product, type) {
			if (!canTrack()) {
				if (!activeDataQueue.find((item) => item.type === 'sendViewProduct')) {
					activeDataQueue.push({
						type: 'sendViewProduct',
						params: { category, product, type },
					})
				}
				return
			}

			try {
				if (window.dw?.ac) {
					if (category && category.id) {
						window.dw.ac.applyContext({ category: category.id })
					}
					if (product && product.id) {
						window.dw.ac._capture({ id: product.id, type: type })
					}
					if (window.dw.ac?._scheduleDataSubmission) {
						window.dw.ac._scheduleDataSubmission()
					}
				}
			} catch (err) {
				console.error(err)
			}
		},
		async sendViewSearch(searchParams, productSearchResult) {
			if (!canTrack()) {
				if (!activeDataQueue.find((item) => item.type === 'sendViewSearch')) {
					activeDataQueue.push({
						type: 'sendViewSearch',
						params: { searchParams, productSearchResult },
					})
				}
				return
			}
			try {
				if (window.dw?.ac) {
					window.dw.ac.applyContext({ searchData: searchParams })
					if (window.dw.ac?._scheduleDataSubmission) {
						window.dw.ac._scheduleDataSubmission()
					}
					productSearchResult.hits.map((productSearchItem: any) => {
						window.dw.ac._capture({
							id: productSearchItem.productId,
							type: 'searchhit',
						})
					})
				}
			} catch (err) {
				console.error(err)
			}
		},
		async sendViewCategory(searchParams, category, productSearchResult) {
			if (!canTrack()) {
				if (!activeDataQueue.find((item) => item.type === 'sendViewCategory')) {
					activeDataQueue.push({
						type: 'sendViewCategory',
						params: { searchParams, category, productSearchResult },
					})
				}
				return
			}
			try {
				if (window.dw?.ac) {
					if (category && category.id) {
						window.dw.ac.applyContext({
							category: category.id,
							searchData: searchParams,
						})
					}
					if (window.dw.ac?._scheduleDataSubmission) {
						window.dw.ac._scheduleDataSubmission()
					}
					productSearchResult.hits.map((productSearchItem: any) => {
						window.dw.ac._capture({
							id: productSearchItem.productId,
							type: 'searchhit',
						})
					})
				}
			} catch (err) {
				console.error(err)
			}
		},
		async trackPage(siteId, localeId, currency) {
			if (!canTrack()) {
				if (!activeDataQueue.find((item) => item.type === 'trackPage')) {
					activeDataQueue.push({
						type: 'trackPage',
						params: { siteId, localeId, currency },
					})
				}
				return
			}
			try {
				const activeDataUrl =
					'/mobify/proxy/ocapi/on/demandware.store/Sites-' +
					siteId +
					'-Site/' +
					localeId +
					'/__Analytics-Start'
				const dwAnalytics = window.dw.__dwAnalytics.getTracker(activeDataUrl)
				if (typeof window.dw.ac == 'undefined') {
					dwAnalytics.trackPageView()
				} else {
					try {
						if (typeof window.dw.ac._setSiteCurrency === 'function') {
							window.dw.ac._setSiteCurrency(currency)
						}
					} catch (err) {
						console.error(err)
					}
					window.dw.ac.setDWAnalytics(dwAnalytics)
				}
			} catch (err) {
				console.error(err)
			}
		},
	}

	/* Start the timer? */
	if (startTimer && typeof window !== 'undefined' && !activeDataTimer) {
		const clearQueue = () => {
			if (activeDataQueue.length > 0 && canTrack()) {
				let i: number = 5
				while (activeDataQueue.length > 0) {
					const queue = activeDataQueue.shift()
					if (queue) {
						const { params, type } = queue
						switch (type) {
							case 'sendViewProduct':
								helper.sendViewProduct(
									params.category,
									params.product,
									params.type
								)
								break
							case 'sendViewCategory':
								helper.sendViewCategory(
									params.searchParams,
									params.category,
									params.productSearchResult
								)
								break
							case 'trackPage':
								helper.trackPage(
									params.siteId,
									params.localeId,
									params.currency
								)
								break
							case 'sendViewSearch':
								helper.sendViewSearch(
									params.searchParams,
									params.productSearchResult
								)
								break
							default:
								break
						}
					}
					i--
					if (i <= 0) {
						break
					}
				}
				if (activeDataQueue.length === 0 && activeDataTimer) {
					clearInterval(activeDataTimer)
					activeDataTimer = null
				}
			}
		}

		/* Attempt to flush the queue every 10 seconds */
		activeDataTimer = setInterval(clearQueue, 10000)
	}

	return helper
}

export default useActiveData
